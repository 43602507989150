import { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { PhrasesContext } from "../../context/phrasesContext";

const SearchBar = ({
  searchKey,
  setSearchKey,
  getSearchService,
  searchType,
  setSearchType,
  setSearchData,
  setCallSearch,
}) => {
  const navigate = useNavigate();

  const inputRef = useRef();

  const [phrases] = useContext(PhrasesContext);
  const { lblVinNumber, lblPlate, lblSearch } = phrases;

  useEffect(() => {
    inputRef.current.focus();
  }, [searchType]);

  return (
    <div
      className="hidden sm:flex w-full h-[70px] absolute top-[89px] left-0 right-0"
      style={{
        backgroundImage: "linear-gradient(to left, #1f7982 0%, #43b8be 100%)",
      }}
    >
      <div
        className="flex gap-6 absolute left-0 right-0 -bottom-[30px] m-auto w-[840px] h-[60px] rounded-[12px] bg-white p-1"
        style={{
          boxShadow:
            "0 14px 32px 0 rgba(39, 44, 52, 0.16), 0 0 8px 0 rgba(39, 44, 52, 0.12)",
        }}
      >
        <div className="h-[52px] flex flex-none justify-center items-center gap-4 bg-[#231f200f] rounded-md px-4">
          <div
            className="flex justify-center items-center gap-2 cursor-pointer"
            onClick={() => {
              setSearchData([]);
              setSearchKey("");
              setCallSearch(false);
              setSearchType(1);
            }}
          >
            {searchType === 1 ? (
              <img
                className="w-4 h-4"
                src="/assets/search/radio-button-checked.svg"
                alt=""
              />
            ) : (
              <img
                className="w-4 h-4"
                src="/assets/search/radio-button.svg"
                alt=""
              />
            )}
            <sapn
              className={`text-sm font-bold ${
                searchType === 1 ? "text-[#be1e2d]" : " text-[#231f20]"
              }`}
            >
              {lblVinNumber?.translation}
            </sapn>
          </div>
          <div
            className="flex justify-center items-center gap-2 cursor-pointer"
            onClick={() => {
              setSearchData([]);
              setSearchKey("");
              setCallSearch(false);
              setSearchType(2);
            }}
          >
            {searchType === 2 ? (
              <img
                className="w-4 h-4"
                src="/assets/search/radio-button-checked.svg"
                alt=""
              />
            ) : (
              <img
                className="w-4 h-4"
                src="/assets/search/radio-button.svg"
                alt=""
              />
            )}
            <sapn
              className={`text-sm font-bold ${
                searchType === 2 ? "text-[#be1e2d]" : " text-[#231f20]"
              }`}
            >
              {lblPlate?.translation}
            </sapn>
          </div>
        </div>
        <div className="w-full flex justify-center items-center gap-4">
          <img className="w-5 h-5" src="/assets/search/search.svg" alt="" />
          <input
            ref={inputRef}
            className="w-full text-sm text-[#231f20] font-bold"
            value={searchKey}
            maxLength={searchType === 1 ? 21 : 6}
            onChange={(e) => setSearchKey(e?.target?.value)}
            onKeyDown={(e) => {
              if (searchType === 1) {
                if (e.which === 13 && e.target.value.length > 3) {
                  navigate(`/search?q=${e.target.value}&type=${searchType}`);
                  getSearchService(e?.target?.value);
                }
              } else {
                if (e.which === 13 && e.target.value.length > 0) {
                  navigate(`/search?q=${e.target.value}&type=${searchType}`);
                  getSearchService(e?.target?.value);
                }
              }
            }}
          />
          {searchKey && (
            <img
              className="w-5 h-5 cursor-pointer"
              src="/assets/search/close-filled.svg"
              alt=""
              onClick={() => {
                setSearchKey("");
                navigate(`/search`);
                getSearchService("", searchType);
              }}
            />
          )}
        </div>
        <button
          className={`flex flex-none justify-center items-center w-[119px] h-[52px] rounded-md bg-[#be1e2d] cursor-pointer ${
            (searchType === 1 && searchKey?.length > 3) ||
            (searchType === 2 && searchKey?.length > 0)
              ? ""
              : "opacity-80 cursor-not-allowed"
          }`}
          onClick={() => {
            if (searchType === 1) {
              if (searchKey.length > 3) {
                navigate(`/search?q=${searchKey}&type=${searchType}`);
                getSearchService(searchKey, searchType);
              }
            } else {
              if (searchKey.length > 0) {
                navigate(`/search?q=${searchKey}&type=${searchType}`);
                getSearchService(searchKey, searchType);
              }
            }
          }}
        >
          <span className="text-sm text-white font-bold">
            {lblSearch?.translation}
          </span>
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
