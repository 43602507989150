import { useContext } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { PhrasesContext } from "../../context/phrasesContext";
// import "./styles.css";

const GridCard = ({ data }) => {
  const {
    images,
    title,
    plateNumber,
    chassisNumber,
    lat,
    long,
    refNumber,
    yardName,
    lockReason,
    lockDestination,
    isLocked,
  } = data;
  const [phrases] = useContext(PhrasesContext);
  const {
    lblBlocked,
    lblRelease,
    lblVinNumber,
    lblPlate,
    lblNoPlate,
    lblStoreId,
    lblBlockDestination,
    lblBlockReason,
  } = phrases;

  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span class="' + className + ' !bg-white"></span>';
    },
  };

  return (
    <div className="relative flex flex-col gap-4 w-full p-4 rounded-[12px] shadow-[0_4px_8px_0_#272c341f] bg-white">
      <div>
        <Swiper
          navigation={true}
          pagination={pagination}
          modules={[Navigation, Pagination]}
          className="mySwiper"
        >
          {images ? (
            images?.slice(0, 10)?.map((image, index) => (
              <SwiperSlide key={index}>
                <img
                  className="w-full h-[176px] md:h-[214px] rounded object-cover"
                  src={image}
                  alt=""
                />
              </SwiperSlide>
            ))
          ) : (
            <img
              src="/assets/svg/ep-logo.svg"
              alt="placeholder"
              className="w-full h-[176px] md:h-[214px] rounded  object-cover"
              style={{
                filter: "grayscale(100%)",
              }}
            />
          )}
        </Swiper>
      </div>
      {yardName && (
        <div className=" absolute top-[20px] right-[20px] z-20 rounded-sm bg-[#231f20cc] h-7 px-3">
          <span className="text-xs text-white font-bold">{yardName}</span>
        </div>
      )}
      <div className="flex justify-between items-center">
        <h3 className="text-sm md:text-[20px] text-[#000000] font-bold">
          {title}{" "}
        </h3>
        {isLocked ? (
          <div
            className="flex items-center justify-center h-5 md:h-[30px] rounded-[20px] px-2 md:px-3"
            style={{
              backgroundImage:
                "linear-gradient(to left,#db2738 16%,#f7727e 89%)",
            }}
          >
            <span className="text-xs text-white font-bold">
              {lblBlocked?.translation}
            </span>
          </div>
        ) : (
          <div
            className="flex items-center justify-center h-5 md:h-[30px] rounded-[20px] px-2 md:px-3"
            style={{
              backgroundImage:
                "linear-gradient(to left, rgb(75, 140, 67) 16%, rgb(75, 181, 67) 89%)",
            }}
          >
            <span className="text-xs text-white font-bold">
              {lblRelease?.translation}
            </span>
          </div>
        )}
      </div>
      <div className="flex justify-between items-start gap-6">
        <div className="flex flex-col gap-4 flex-1">
          <div className="flex gap-4">
            <div className="flex flex-col gap-2">
              <p className="text-xs md:text-sm text-[#000000de]">
                {lblPlate?.translation}
              </p>
              <p className="text-xs md:text-sm text-[#000] font-bold">
                {plateNumber ? plateNumber : lblNoPlate?.translation}
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <p className="text-xs md:text-sm text-[#be1e2d]">
                {lblVinNumber?.translation}
              </p>
              <p className="text-xs md:text-sm text-[#be1e2d] font-bold">
                {chassisNumber ? chassisNumber : "-"}
              </p>
            </div>
          </div>

          <div>
            <div className="w-full h-[1px] bg-[#d0d5dd]"></div>
            <div className="leading-none pt-2 md:pt-4">
              <span className="text-xs md:text-sm text-[#000000de] leading-none me-2">
                {lblStoreId?.translation}
              </span>
              <span className="text-xs md:text-sm text-[#000] leading-none font-bold">
                {refNumber}
              </span>
            </div>
          </div>
        </div>
        <a
          href={`https://www.google.com/maps/@${lat},${long},14z?hl=en`}
          target="_blank"
          rel="noreferrer"
        >
          <div className="flex flex-col relative rounded overflow-hidden">
            <img
              className="w-[53px] md:w-[80px] h-[59px] md:h-[88px] shadow-[0_4px_8px_0_#272c341f]"
              src="/assets/GridCard/map.png"
              alt=""
            />
            <p className=" absolute bottom-0 left-0 right-0 text-xs text-center text-[#ffffffde] bg-[#231f20] h-6 leading-none py-1">
              الموقع
            </p>
          </div>
        </a>
      </div>
      <div className="hidden md:hidden justify-evenly items-center">
        <div className="flex flex-col items-center gap-2">
          <p className="text-sm text-[#000000de]">المصنع</p>
          <p className="text-sm text-[#000] font-bold">ميتسوبيشي</p>
        </div>
        <div className="w-[1px] h-10 bg-[#d0d5dd]"></div>
        <div className="flex flex-col items-center gap-2">
          <p className="text-sm text-[#000000de]">نوع المركبة</p>
          <p className="text-sm text-[#000] font-bold">صالون</p>
        </div>
        <div className="w-[1px] h-10 bg-[#d0d5dd]"></div>
        <div className="flex flex-col items-center gap-2">
          <p className="text-sm text-[#000000de]">الموديل</p>
          <p className="text-sm text-[#000] font-bold">جالانت</p>
        </div>
      </div>

      {/* <div className="flex flex-col gap-4">
        <div className="flex items-center gap-4">
          <div className="flex justify-center items-center w-9 md:w-11 h-9 md:h-11 bg-[#f0eeee] rounded">
            <img
              className="w-4 md:w-6 h-4 md:h-6"
              src="/assets/GridCard/enterprise.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm text-[#000000de] leading-none">
              {lblBlockDestination?.translation}
            </p>
            <p className="text-sm text-[#000] font-bold leading-none">
              {lockDestination}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-4">
          <div className="flex justify-center items-center w-9 md:w-11 h-9 md:h-11 bg-[#f0eeee] rounded">
            <img
              className="w-4 md:w-6 h-4 md:h-6"
              src="/assets/GridCard/doc.svg"
              alt=""
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="text-sm text-[#000000de] leading-none">
              {lblBlockReason?.translation}
            </p>
            <p className="text-sm text-[#000] font-bold leading-none">
              {lockReason}
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default GridCard;
