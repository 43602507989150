import { useMemo } from "react";
import Chart from "react-apexcharts";

export default function PieChart({ chartData }) {
  const colors = ["#f8961e", "#f9c74f", "#f3722c", "#b91a2a"];
  const colorsBG = [
    "bg-[#f8961e]",
    "bg-[#f9c74f]",
    "bg-[#f3722c]",
    "bg-[#b91a2a]",
  ];

  const chartSeries = useMemo(() => {
    let series = [];
    let labels = [];
    chartData?.map((item) => {
      series.push(item?.count);
      labels.push(item?.label);
    });
    return {
      series,
      labels,
    };
  }, []);
  const barData = {
    options: {
      labels: chartSeries?.labels,
      states: {
        hover: {
          filter: {
            type: "darken",
          },
        },
      },
      selection: {
        enabled: false,
      },

      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: false,
              total: {
                showAlways: false,
                show: false,
                label: [""],
              },
            },
          },
        },
      },
      chart: {
        type: "donut",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 260,
            },
          },
        },
      ],
      colors: colors,
    },
    // #b91a2a
    // #f9c74f
    // #f3722c
    // #f8961e
  };
  return (
    <div
      id="chart"
      className="flex flex-col w-full justify-center align-center gap-4 p-2 md:p-6"
    >
      <div className="m-auto">
        <Chart
          options={barData.options}
          series={chartSeries?.series}
          type="pie"
          height={300}
          width={300}
        />
      </div>

      <div className="flex justify-between items-center">
        {chartData?.map((el, index) => (
          <div
            key={index}
            className="flex flex-col justify-center items-center"
          >
            <p
              className={`min-w-[84px] h-5 flex justify-center items-center text-xs text-[#ffffff] ${colorsBG[index]} rounded-xl`}
            >
              {el?.label}
            </p>
            <p className="w-full flex justify-center gap-3 text-[24px] text-[#231f20] font-bold">
              <span>{el?.count}</span>
            </p>
            <p className="text-sm text-[#231f20a3] font-bold">مركبة</p>
          </div>
        ))}
      </div>
    </div>
  );
}
