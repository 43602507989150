import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import Input from "../../components/Input";
import SelectInput from "../../components/SelectInput";
import LoginLayout from "../../components/loginLayout";
import { countriesAr } from "../../constants/countriesAr";
import { useAuthContext } from "../../context/authContext";
import { PhrasesContext } from "../../context/phrasesContext";
import { loginWithOtp } from "../../services/api";

export default function LoginPage() {
  const [phrases] = useContext(PhrasesContext);
  const {
    lblAppName,
    WelcomeBack,
    lblEnterRegistered,
    lblOtpCodeBy,
    lblValidationMobile,
    btnSMS,
    btnWhatsApp,
  } = phrases;
  const { setAuthStatus } = useAuthContext();
  const navigate = useNavigate();

  const [countryCode, setCountryCode] = useState("+971");
  const [mobile, setMobile] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signInTypeNumber, setSignInTypeNumber] = useState(0);
  const [buttonsError, setButtonsErrorError] = useState(true);

  const handleChangeMobile = (value) => {
    setMobile(value);
    if (!value) {
      setError(lblValidationMobile?.translation);
      setButtonsErrorError(true);
    } else if (value.length < 9 || value.length > 13) {
      setError(lblValidationMobile?.translation);
      setButtonsErrorError(true);
    } else {
      const error = mobileValidationRules(value, countryCode);
      setError(error);
      setButtonsErrorError(error);
    }
  };

  const mobileValidationRules = (value, inputCode = undefined) => {
    const phoneUtil =
      require("google-libphonenumber").PhoneNumberUtil.getInstance();

    const code = inputCode
      ? inputCode
      : document?.getElementById("code")?.innerText;
    let error = "";
    if (!value) {
      error = lblValidationMobile?.translation;
    } else if (value.length < 3 || value.length > 13) {
      error = lblValidationMobile?.translation;
    } else {
      try {
        const number = phoneUtil.parse(value, code);
        if (
          !phoneUtil.isValidNumber(number) ||
          phoneUtil.getNumberType(number) === -1 ||
          phoneUtil.getNumberType(number) === 0
        ) {
          error = lblValidationMobile?.translation;
        } else {
          if (code === "EG" && value[0] == 0) {
            error = lblValidationMobile?.translation;
          }
        }
      } catch (error) {
        error = lblValidationMobile?.translation;
      }
    }
    return error;
  };

  const handleOnChangeIput = (e) => {
    setError(null);
    let newValue = "";
    if (e.target.value?.charAt(0) === "0") {
      newValue = e.target.value?.slice(1)?.replaceAll(" ", "");
      setMobile(newValue);
    } else {
      newValue = e.target.value?.replaceAll(" ", "");
      setMobile(newValue);
    }
    handleChangeMobile(newValue);
  };

  const loginService = (signInType) => {
    setLoading(true);
    setSignInTypeNumber(signInType);
    return loginWithOtp({
      countryCode: `${countryCode}`,
      mobileNo: mobile,
      signInType: signInType,
    }).then((res) => {
      setLoading(false);
      setSignInTypeNumber(0);
      if (res?.code === 0) {
        setError(res?.message);
        // navigate("/otp");
      } else if (res?.code === 1) {
        setError("");
        localStorage.setItem("otpSessionId", res?.data?.otpSessionId);
        localStorage.setItem("loginNumber", mobile);
        localStorage.setItem("loginCode", countryCode);

        setAuthStatus({
          otpSessionId: res?.data?.otpSessionId,
          isAuthorized: false,
        });
        navigate("/otp");
      } else {
      }
    });
  };

  const [result, setResult] = useState();
  const handleOnChange = (res) => {
    setResult(res);
  };

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/");
    }
  }, []);
  return (
    <LoginLayout>
      {/* <h1 className="text-4xl text-[#212121] font-bold mb-2"> */}
      {/* {WelcomeBack?.translation} */}
      <h1 className="text-xl md:text-2xl text-[#212121] text-center font-bold mb-2">
        {lblAppName?.translation}
      </h1>
      <p className="text-sm md:text-base text-[#212121] font-medium">
        {lblEnterRegistered?.translation}
      </p>
      <div className="relative w-full sm:w-[340px] mt-8 mb-4">
        {/* <Input type="text" placeholder={"05 000 0000"} error /> */}
        <div
          className={`w-full flex flex-row gap-[-1px] items-center rounded-lg overflow-hidden ${
            error
              ? "text-[#be202e] border-[1px] border-[#be202e] outline-[6px] outline-[#be202e33]"
              : "text-[#000] border-[1px] border-[#cacaca]"
          }`}
          style={{ direction: "ltr", outlineStyle: error ? "solid" : "" }}
        >
          <div className="flex-1">
            <SelectInput
              rounded={false}
              labelKey={"name"}
              valueKey={"dial_code"}
              value={countryCode}
              setValue={(value) => {
                setCountryCode(value);
                handleChangeMobile(mobile);
              }}
              options={
                // locale == "en" ? countriesEn :
                countriesAr
              }
            />
          </div>

          <div className="flex-[1.5]">
            <Input
              type={"text"}
              error={error}
              value={mobile}
              onChange={handleOnChangeIput}
              onClick={() => loginService(1)}
              isValidValue={mobile === "" || buttonsError}
              haveStartBorder={false}
              placeholder={"05XXXXXXXX"}
              max={countryCode === "+971" ? 10 : 11}
            />
          </div>
        </div>
        <p className="h-4 text-xs text-[#be202e] font-bold mt-2">{error}</p>
      </div>
      <div className="flex items-center gap-4 mb-4">
        <span className="w-14 md:w-16 h-[1px] bg-[#cacaca]"></span>
        <span className="text-xs md:text-sm text-[#212121] font-medium">
          {lblOtpCodeBy?.translation}
        </span>
        <span className="w-14 md:w-16 h-[1px] bg-[#cacaca]"></span>
      </div>
      <div className="w-full flex justify-center items-center gap-4">
        {/* <Button
          text={btnWhatsApp?.translation}
          icon="/assets/login/whatsapp.svg"
          disabledIcon="/assets/login/whatsappdim.svg"
          background="bg-[#00a884]"
          size="large"
          hasBorder
          disabled={mobile === "" || buttonsError}
          loading={loading && signInTypeNumber === 2}
          onClick={() => loginService(2)}
        /> */}
        {/* 
        
        
        */}
        <Button
          text={btnSMS?.translation}
          icon="/assets/login/sms.svg"
          disabledIcon="/assets/login/smsdim.svg"
          background="bg-[#bd202e]"
          size="large"
          hasBorder
          fullWidth
          disabled={mobile === "" || buttonsError}
          loading={loading && signInTypeNumber === 1}
          onClick={() => loginService(1)}
        />
      </div>
    </LoginLayout>
  );
}
