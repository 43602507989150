export const normalize = (arr = [], key = "id") => {
  return arr?.reduce((acc, currentValue) => {
    acc[currentValue[key]] = currentValue;
    return acc;
  }, {});
};

export const blockSpecialCharacters = (e) => {
  let key = e.key;

  let keyCharCode = key.charCodeAt(0);

  // console.log(keyCharCode);
  // 0-9
  if (keyCharCode >= 48 && keyCharCode <= 57) {
    return key;
  }
  // A-Z
  if (keyCharCode >= 65 && keyCharCode <= 90) {
    return key;
  }
  // // a-z
  // if (keyCharCode >= 97 && keyCharCode <= 122) {
  //   return key;
  // }
  // // Arabic
  // if (keyCharCode >= 1000) {
  //   return key;
  // }
  // if (keyCharCode === 32) {
  //   return key;
  // }
  if (keyCharCode === 39) {
    return key;
  }

  return false;
};
