import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import useOutsideAlert from "../../customHooks/useOutsideAlert";

const Dropdown = ({ yardId, setYardId, getDashitemsService }) => {
  const branches = JSON.parse(localStorage.getItem("branches"));
  const [dropdown, setDropdown] = useState(false);

  const wrapperRef = useRef();

  useOutsideAlert(wrapperRef, setDropdown);

  return (
    <>
      {branches?.length > 1 && (
        <div
          ref={wrapperRef}
          className={`w-full sm:w-auto border-[1px] border-[#d0d5dd] border-solid relative ${
            dropdown ? "rounded-tl-md rounded-tr-md" : "rounded-md"
          }`}
          style={{
            boxShadow:
              "0 14px 32px 0 rgba(39, 44, 52, 0.16), 0 0 8px 0 rgba(39, 44, 52, 0.12)",
          }}
        >
          <div
            className={`w-full sm:w-[220px] h-10 bg-white flex justify-between items-center rounded-md p-4 cursor-pointer`}
            onClick={() => setDropdown(!dropdown)}
          >
            <span className="text-sm text-[#333333] font-bold">
              {
                branches?.find((branch) => branch?.id === Number(yardId))
                  ?.yardName
              }
            </span>
            <img
              src="/assets/svg/arrow-down.svg"
              alt="arrow"
              className={`w-4 h-4 ${dropdown ? "rotate-180" : ""}`}
            />
          </div>
          {dropdown && (
            <div
              className="absolute top-[41px] z-10 flex flex-col gap-2 w-full sm:w-[220px] p-2 bg-white border-[1px] border-t-0 border-[#d0d5dd] border-solid"
              style={{
                boxShadow:
                  "0 14px 32px 0 rgba(39, 44, 52, 0.16), 0 0 8px 0 rgba(39, 44, 52, 0.12)",
              }}
            >
              {branches?.map((branch) => (
                //     <Link to={`/dashboard?yard=${branch?.id}`} key={branch?.id}>
                <Link to={`/dashboard`} key={branch?.id}>
                  <div
                    className={`w-full h-10 bg-white flex justify-between items-center p-4 cursor-pointer border-[1px] border-solid rounded-md ${
                      branch?.id === Number(yardId)
                        ? "border-[#856c49]"
                        : "border-[#d0d5dd]"
                    }`}
                    onClick={() => {
                      setYardId(branch?.id);
                      setDropdown(!dropdown);
                      localStorage.setItem("yard", branch?.id);
                      // getDashitemsService(branch?.id);
                    }}
                  >
                    <span className="text-sm text-[#333333] font-bold">
                      {branch?.yardName}
                    </span>
                    {branch?.id === Number(yardId) && (
                      <img
                        src="/assets/svg/selected.svg"
                        alt="arrow"
                        className={`w-5 h-5`}
                      />
                    )}
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dropdown;
