import { useHref } from "react-router-dom";
import { useAuthContext } from "../context/authContext";
import User from "../modules/nav/user";
import NavBarHeader from "./nav";

export default function RootLayout({ children }) {
  const { authStatus } = useAuthContext();
  const { isAuthorized } = authStatus;
  const path = useHref();

  return (
    <div
      className={`w-full  ${
        isAuthorized
          ? path !== "/"
            ? "h-screen bg-[#f8f7f1]"
            : "bg-[#f8f7f1]"
          : "bg-[#f5f5f5]"
      }`}
      dir="rtl"
    >
      {isAuthorized ? (
        path !== "/" ? (
          <NavBarHeader />
        ) : (
          <nav className=" bg-transparent fixed w-full z-50">
            <div className="flex items-center justify-between max-w-[1300px] m-auto px-4 lg:px-0 py-5 relative">
              <div
                className=" absolute top-4 left-10 z-20 h-16 bg-transparent sm:bg-white flex rounded-[40px]"
                style={{
                  boxShadow: "0 1px 4px 0 rgba(35, 31, 32, 0.12)",
                }}
              >
                <User />
              </div>
            </div>
          </nav>
        )
      ) : null}
      <div
        className={`w-full max-w-[1300px] m-auto  ${
          isAuthorized ? (path !== "/" ? "pt-[89px] pb-20" : "") : ""
        }`}
      >
        {children}
      </div>
    </div>
  );
}
