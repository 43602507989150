import axios from "axios";
import { useEffect, useState } from "react";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function useItems(
  carDetailsId,
  pageNumber,
  pageSize,
  filterColumn,
  filterIds,
  submitType
) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [carsData, setCarsData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [filterData, setFilterData] = useState([]);

  const getDetails = (reset) => {};

  useEffect(() => {
    setCarsData([]);
    setFilterData([]);
  }, [carDetailsId, submitType]);

  useEffect(() => {
    setLoading(true);
    setError(false);
    let cancel;
    if (carDetailsId != null) {
      axios({
        method: "POST",
        url: `${BASE_URL}Statistics/SectionDetails`,
        cancelToken: new axios.CancelToken((c) => (cancel = c)),
        headers: {
          SessionId: JSON.parse(localStorage.getItem("userData"))?.sessionId,
          YardId: localStorage?.getItem("yard"),
          Lang: "ar",
        },
        data: {
          code: carDetailsId?.toString(),
          pageNumber: pageNumber,
          pageSize: pageSize,
          filterColumn: filterColumn?.toString(),
          filter: submitType === "reset" ? [] : filterIds,
          isFilterApplied:
            submitType === "reset"
              ? false
              : filterIds?.length > 0
              ? true
              : false,
        },
      })
        .then((res) => {
          const filterArray = res?.data?.data?.filterData;

          const filter = filterArray?.filter((data, index) => {
            return (
              index === filterArray.findIndex((o) => o?.value === data?.value)
            );
          });

          setFilterData((prev) => [...prev, ...filter]);
          setCarsData((prev) => {
            return [...new Set([...prev, ...(res?.data?.data?.cars || [])])];
          });
          setHasMore(res?.data?.data?.cars?.length > 0);
          setLoading(false);
        })
        .catch((e) => {
          if (axios.isCancel(e)) return;
          setError(true);
        });
      return () => cancel();
    }
  }, [carDetailsId, pageNumber, submitType]);

  return {
    loading,
    error,
    carsData,
    hasMore,
    filterData,
    setFilterData,
    getDetails,
  };
}
