import { blockSpecialCharacters } from "../../utils/helper";

const Input = ({ label, type, error, onChange,onClick, isValidValue,onBlur, max ,placeholder}) => {
  return (
    <>
      <div>
        <input
          type={type}
          id="error"
          className={`w-full h-12 text-base rounded-lg block p-2.5 font-medium placeholder-[#838383]`}
          //bg-red-50 border-red-500 text-red-900 placeholder-red-700 focus:ring-red-500 focus:border-red-500 dark:text-red-500 dark:placeholder-red-500 dark:border-red-500
          placeholder={placeholder}
          autocomplete="off"
          onChange={onChange}
          min={0}
          maxLength={max}
          onKeyDown={(e) => {
            if (e.key === "Enter"&&!isValidValue) {
              onClick()
            }
            if (!blockSpecialCharacters(e)) {
              e.preventDefault();
            }
          }}
        />
        {/* <p className="mt-2 text-sm text-red-600 dark:text-red-500">
          <span className="font-medium">Oh, snapp!</span> Some error message.
        </p> */}
      </div>

      {/* {
              <div className="mb-6">
        <label
          for="success"
          className="block mb-2 text-sm font-medium text-green-700 dark:text-green-500"
        >
          Your name
        </label>
        <input
          type="text"
          id="success"
          className="bg-green-50 border border-green-500 text-green-900 dark:text-green-400 placeholder-green-700 dark:placeholder-green-500 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5 dark:bg-gray-700 dark:border-green-500"
          placeholder="Success input"
        />
        <p className="mt-2 text-sm text-green-600 dark:text-green-500">
          <span className="font-medium">Well done!</span> Some success message.
        </p>
      </div>

      } */}
    </>
  );
};

export default Input;
