import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CardLayout from "../../components/CardLayout";
import CarsSideInfinit from "../../components/CarsSideInfinit";
import Dropdown from "../../components/Dropdown";
import HeaderNav from "../../components/HeaderNav";
import { useAuthContext } from "../../context/authContext";
import { PhrasesContext } from "../../context/phrasesContext";
import { getDashitems } from "../../services/api";

export default function Home() {
  const [searchParams, setSearchParams] = useSearchParams();
  // const yard = searchParams.get("yard");
  const yard = localStorage?.getItem("yard");
  const [yardId, setYardId] = useState(Number(yard));

  const [phrases] = useContext(PhrasesContext);
  const { DashBoardTitle, lblOtherEmirates, lblVehicleList } = phrases;
  const navigate = useNavigate();
  const { authStatus, setAuthStatus } = useAuthContext();

  const lang = window.localStorage.getItem("lang");
  const [openCarsSide, setOpenCarsSide] = useState(false);
  const [carDetailsId, setCarDetailsId] = useState(null);
  const [carDetailsTitle, setCarDetailsTitle] = useState(null);
  const [carDetailsObj, setCarDetailsObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newData, setNewData] = useState([]);
  const [windowWith, setWindowWith] = useState(0);
  const getDashitemsService = (yardId) => {
    setLoading(true);
    return getDashitems(yardId)
      .then((res) => {
        setLoading(false);
        if (res == "401") {
          localStorage.removeItem("otpSessionId");
          localStorage.removeItem("loginCode");
          localStorage.removeItem("loginNumber");
          localStorage.removeItem("userData");
          setAuthStatus({
            otpSessionId: "",
            userData: "",
            isAuthorized: false,
          });
          navigate("/login");
        } else {
          const groupByRowNo = groupBy("rowNo");
          const groupByRowNo1 = groupBy("columnNo");
          const entries = Object.values(groupByRowNo(res?.data));
          setNewData(entries.map((el) => Object.values(groupByRowNo1(el))));
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  const groupBy = (key) => {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  };

  useEffect(() => {
    if (yard) {
      getDashitemsService(yardId);
    } else {
      navigate("/");
    }
  }, [yardId]);

  useEffect(() => {
    setWindowWith(window?.innerWidth);
    if (document.getElementById("mySidenav")) {
      document.getElementById("overlay").style.visibility = "visible";
      if (window?.innerWidth > 777) {
        document.getElementById("mySidenav").style.width = "620px";
      } else {
        document.getElementById("mySidenav").style.width = "100%";
      }
    }
  }, [windowWith]);

  // if (loading)
  //   return (
  //     <div className="flex justify-center items-center h-[80vh]">
  //       <img
  //         src="/assets/loading.png"
  //         alt="loading"
  //         className="rotate w-16 h-16 cursor-pointer"
  //       />
  //     </div>
  //   );

  return (
    <>
      <div className="relative px-4 lg:px-0">
        <div className="flex felx-row justify-between items-center mt-4 mb-3 lg:mb-8">
          <div className="w-full flex flex-col sm:flex-row justify-start items-start sm:items-center gap-4 sm:gap-6">
            <HeaderNav
              text={DashBoardTitle?.translation}
              icon="/assets/svg/linear_chart.svg"
            />
            <Dropdown
              yardId={yardId}
              setYardId={setYardId}
              getDashitemsService={getDashitemsService}
            />
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center items-center h-[80vh]">
            <img
              src="/assets/loading.png"
              alt="loading"
              className="rotate w-16 h-16 cursor-pointer"
            />
          </div>
        ) : (
          <div className="flex flex-col gap-4 lg:gap-6">
            {newData?.map((sectionEl, index) => (
              <div
                key={index}
                className="w-full flex flex-col lg:flex-row items-stretch gap-4 lg:gap-6"
              >
                {sectionEl?.map((sectionElement, ind) => (
                  <div
                    key={ind}
                    className={`${
                      sectionElement[0]?.chartType === "Line"
                        ? "w-full lg:w-[68.33333336%]"
                        : sectionElement[1]?.chartType === "Pie"
                        ? "w-full lg:w-[32.333333%]"
                        : sectionElement[0]?.length === 1
                        ? "w-full flex flex-col gap-4 lg:gap-6"
                        : // 33.333333333333336

                          `w-full ${
                            sectionElement[0]?.size === 6
                              ? "lg:w-[50%]"
                              : sectionElement[0]?.size === 4
                              ? "lg:w-[33.333333333333336%]"
                              : sectionElement[0]?.size === 2
                              ? "lg:w-[15.777778%]"
                              : ""
                          } flex flex-col gap-4 lg:gap-4`
                    }`}
                  >
                    {sectionElement?.map((section) => (
                      <CardLayout
                        key={section?.id}
                        title={section?.title}
                        section={section}
                        subSections={section?.subSections}
                        chartData={section?.chartData}
                        totalPercentage={section?.totalPercentage}
                        color="text-[#ffffff]"
                        background={`linear-gradient(to left, ${section?.colorFirst} 0%, ${section?.colorLast} 100%)`}
                        cardType={
                          section?.isMerged
                            ? "normal"
                            : section?.subSections?.length > 3
                            ? "tworow"
                            : section?.isChart && section?.columnNo === 1
                            ? "lineChart"
                            : section?.isChart && section?.columnNo === 2
                            ? "pieChart"
                            : section?.title === "logo"
                            ? "logo"
                            : "onerow"
                          // cardType="pieChart"
                        }
                        icon={section?.icon}
                        setCarDetailsTitle={setCarDetailsTitle}
                        setCarDetailsId={setCarDetailsId}
                        setCarDetailsObj={setCarDetailsObj}
                        onClick={() => {
                          // setCarDetailsId(section?.id);
                          setOpenCarsSide(true);
                          setTimeout(() => {
                            setWindowWith(window?.innerWidth);
                            document.querySelector("body").style.overflow =
                              "hidden";
                            document.getElementById(
                              "overlay"
                            ).style.visibility = "visible";
                            if (window && window?.innerWidth > 777) {
                              document.getElementById("mySidenav").style.width =
                                "620px";
                            } else {
                              document.getElementById("mySidenav").style.width =
                                "100%";
                            }
                          }, 0);
                        }}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}

        {openCarsSide && (
          <>
            <div
              className="fixed top-0 z-[4] w-full h-screen bg-[#f5f5f5] opacity-20"
              id="overlay"
            ></div>
            <CarsSideInfinit
              carDetailsTitle={carDetailsTitle}
              carDetailsId={carDetailsId}
              setCarDetailsId={setCarDetailsId}
              carDetailsObj={carDetailsObj}
            />
          </>
        )}
      </div>
    </>
  );
}
