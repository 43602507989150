import { useContext, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { PhrasesContext } from "../context/phrasesContext";
import User from "../modules/nav/user";

const NavBarHeader = (props) => {
  const [phrases] = useContext(PhrasesContext);
  const { lblMainMenu, lblSearch } = phrases;

  const [openUserMenu, setOpenUserMenu] = useState(false);

  return (
    <nav className=" bg-white fixed w-full z-50 border-b shadow-[0_1px_4px_0_#231f201f]">
      <div className="flex items-center justify-between max-w-[1300px] m-auto px-4 lg:px-0 py-5">
        <Link to="/dashboard">
          <img
            className="lg:block"
            src="/assets/svg/ep-logo.svg"
            alt="ep logo"
          />
        </Link>
        <div className="flex gap-6">
          <NavLink to="/dashboard">
            {({ isActive }) => (
              <div className="relative hidden md:flex gap-2 cursor-pointer">
                {isActive ? (
                  <img src="/assets/nav/dashboard-active.svg" alt="dashboard" />
                ) : (
                  <img src="/assets/nav/dashboard.svg" alt="dashboard" />
                )}
                <span
                  className={`text-sm font-bold ${
                    isActive ? "text-[#be1e2d]" : "text-[#231f20]"
                  }`}
                >
                  {lblMainMenu?.translation}
                </span>
                {isActive && (
                  <div className=" absolute -bottom-[34px] w-full h-1 bg-[#be1e2d] rounded-tr rounded-tl"></div>
                )}
              </div>
            )}
          </NavLink>

          <NavLink to="/search">
            {({ isActive, isPending, isTransitioning }) => (
              <div className="relative hidden md:flex gap-2 cursor-pointer">
                {isActive ? (
                  <img src="/assets/nav/search-active.svg" alt="dashboard" />
                ) : (
                  <img src="/assets/nav/search.svg" alt="dashboard" />
                )}
                <span
                  className={`text-sm font-bold ${
                    isActive ? "text-[#be1e2d]" : "text-[#231f20]"
                  }`}
                >
                  {lblSearch?.translation}
                </span>
                {isActive && (
                  <div className=" absolute -bottom-[34px] w-full h-1 bg-[#be1e2d] rounded-tr rounded-tl"></div>
                )}
              </div>
            )}
          </NavLink>
        </div>

        <div className=" items-center gap-2 lg:gap-4 md:inline-flex ">
          {false ? (
            <button className="flex flex-row-reverse gap-2 transition-all duration-300 px-4 font-semibold md:px-1 lg:px-4 xl:px-8 text-sm py-3 xl:py-3 bg-gold-100 text-white rounded focus:outline-none ">
              <span>{"تسجيل الدخول"}</span>
              <img
                src="/assets/img/login/login.svg"
                alt="logout"
                className="w-5 h-5"
              />
            </button>
          ) : (
            <User />
          )}
        </div>
      </div>
    </nav>
  );
};
export default NavBarHeader;
