"use client";

const HeaderNav = ({ text, icon }) => {
  return (
    <div className="flex felx-row items-center gap-4">
      <img src={icon} alt="" />
      <span className="text-lg lg:text-2xl text-[#272c34] font-bold">
        {text}
      </span>
    </div>
  );
};

export default HeaderNav;
