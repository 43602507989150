const Button = ({
  text,
  icon,
  disabledIcon,
  variant,
  hasBorder,
  fullWidth,
  loading,
  background,
  disabled,
  size,
  signInType,
  onClick,
}) => {
  return (
    <>
      <button
        type="button"
        // w-[162px]
        className={`
        ${fullWidth ? "w-full" : ""}
        px-4 text-base font-medium text-center flex justify-center items-center gap-2 rounded-lg ${background} ${
          disabled
            ? "text-[#838383] bg-[#dedede] pointer-events-none"
            : loading
            ? "pointer-events-none text-white"
            : "text-white"
        } ${size === "large" ? " h-12" : " h-8"}`}
        //  focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800
        onClick={onClick}
      >
        {loading ? (
          <img
            className="w-8 h-8 text-white rotate"
            src={"/assets/login/loading.png"}
            alt=""
            width={32}
            height={32}
          />
        ) : (
          <>
            {text && <span>{text}</span>}
            {icon && (
              <img
                className="w-6 h-6 text-white"
                src={disabled ? disabledIcon : icon}
                alt=""
                width={24}
                height={24}
              />
            )}
          </>
        )}
      </button>
    </>
  );
};

export default Button;
