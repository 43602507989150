import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  createBrowserRouter,
} from "react-router-dom";
import RootLayout from "./components/layout";
import Protected from "./components/protected";
import { AuthWrapper } from "./context/authContext";
import { PhrasesProvider } from "./context/phrasesContext";
import Dashboard from "./pages/dashboard";
import Home from "./pages/home";
import Login from "./pages/login";
import Otp from "./pages/otp";
import Search from "./pages/search";

const route = [
  { path: "/login", element: <Login /> },
  { path: "/otp", element: <Otp /> },
  {
    path: "/",
    element: (
      <Protected>
        <Home />
      </Protected>
    ),
  },
  {
    path: "/dashboard",
    element: (
      <Protected>
        <Dashboard />
      </Protected>
    ),
  },
  {
    path: "/search",
    element: (
      <Protected>
        <Search />
      </Protected>
    ),
  },
];

export default function App(props) {
  const router = createBrowserRouter(route);
  return (
    <BrowserRouter>
      <PhrasesProvider>
        <AuthWrapper>
          <RootLayout>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/otp" element={<Otp />} />
              <Route
                path="/"
                element={
                  <Protected>
                    <Dashboard />
                  </Protected>
                }
              />
              <Route
                path="/dashboard"
                element={
                  <Protected>
                    <Home />
                  </Protected>
                }
              />
              <Route
                path="/search"
                element={
                  <Protected>
                    <Search />
                  </Protected>
                }
              />
            </Routes>
          </RootLayout>
        </AuthWrapper>
      </PhrasesProvider>
    </BrowserRouter>
  );
}
