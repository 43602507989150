const CardHeader = ({ text, color, background, icon }) => {
  return (
    <div
      className={`flex flex-row justify-between items-center gap-3 h-11 py-[12px] px-4`}
      style={{ backgroundImage: background }}
    >
      <span className={`text-base font-bold ${color}`}>{text}</span>
      <img src={icon} alt="" width={24} height={24} className="w-6 h-6" />
    </div>
  );
};

export default CardHeader;
