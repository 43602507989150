export default function LoginLayout({ children }) {
  return (
    <>
      <div className="relative flex justify-center items-center  w-full h-screen">
        <div className="w-full flex items-center bg-white border-[2px] border-solid border-[hsl(0 0% 93% / 1)] shadow-[0px_8px_50px_rgba(0,0,0,0.04)]">
          <div className="w-full lg:w-[47%] px-4 lg:px-[135px] flex flex-col justify-center items-center">
            <div className="flex flex-col justify-center items-center">
              <div className="flex justify-center items-center mb-10 lg:hidden">
                <img
                  className="relative w-[320px] lg:w-[410px] h-[104px]"
                  src="/assets/svg/ep-logo.svg"
                  alt=""
                />
              </div>
              {children}
            </div>
          </div>
          <div className="w-0 lg:w-[1px] h-[528px] bg-[#000000]"></div>
          <div
            className="hidden lg:flex justify-center items-center w-[53%] h-[768px] rounded-3xl overflow-hidden"
            style={{
              backgroundImage: `url("/assets/login/backImage.svg")`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
            }}
          >
            <div>
              <img
                className="relative w-[410px] h-[104px]"
                src="/assets/svg/ep-logo.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
