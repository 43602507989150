import { useEffect, useState } from "react";
import CardContent from "../CardContent";

const CardContentTwoRow = ({
  subSections,
  onClick,
  setCarDetailsTitle,
  setCarDetailsId,
}) => {
  const [newSubSections, setNewSubSections] = useState([]);

  const groupBy = (key) => {
    return function group(array) {
      return array.reduce((acc, obj) => {
        const property = obj[key];
        acc[property] = acc[property] || [];
        acc[property].push(obj);
        return acc;
      }, {});
    };
  };

  useEffect(() => {
    const groupByRowNo = groupBy("rowNo");
    const groupByRowNo1 = groupBy("columnNo");
    const entries = Object.values(groupByRowNo(subSections));
    setNewSubSections(entries);
  }, []);

  return (
    <>
      {newSubSections?.map((newSubSection, index) => (
        <div
          key={index}
          className="flex justify-around items-center bg-white border-b-[1px] border-solid border-[#d0d5dd] last:border-0"
        >
          {newSubSection.map((subSection, index) => (
            <div
              key={index}
              className={`w-full flex flex-col justify-center items-center pt-4 pb-6 border-l-[1px] border-solid border-[#d0d5dd] last:border-0 ${
                subSection?.openSideMenu && Math.sign(subSection?.count) === 1
                  ? "cursor-pointer"
                  : ""
              } ${
                subSection?.size === 12
                  ? "lg:w-full"
                  : subSection?.size === 6
                  ? "lg:w-[50%]"
                  : subSection?.size === 4
                  ? "lg:w-[33.333333333333336%]"
                  : subSection?.size === 2
                  ? "lg:w-[15.777778%]"
                  : ""
              }`}
              onClick={() => {
                if (
                  subSection?.openSideMenu &&
                  Math.sign(subSection?.count) === 1
                ) {
                  onClick();
                  setCarDetailsTitle(subSection?.sideMenuLabel);
                  setCarDetailsId(subSection?.id);
                }
              }}
            >
              <CardContent
                subSection={subSection}
                subSectionsCount={subSections?.length}
              />
            </div>
          ))}
        </div>
      ))}
    </>
  );
};

export default CardContentTwoRow;
