import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/authContext";
import { getUserBranches } from "../../services/api";

export default function Dashboard() {
  // const [phrases] = useContext(PhrasesContext);
  // const { DashBoardTitle, lblOtherEmirates, lblVehicleList } = phrases;
  // const navigate = useNavigate();
  // const { authStatus, setAuthStatus } = useAuthContext();

  // const lang = window.localStorage.getItem("lang");
  const navigate = useNavigate();

  const { authStatus, setAuthStatus } = useAuthContext();

  const [loading, setLoading] = useState(true);

  const [branches, setBranches] = useState([]);

  const getgetUserBranchesService = () => {
    setLoading(true);
    return getUserBranches()
      .then((res) => {
        setLoading(false);
        if (res == "401") {
          localStorage.removeItem("otpSessionId");
          localStorage.removeItem("loginCode");
          localStorage.removeItem("loginNumber");
          localStorage.removeItem("userData");
          setAuthStatus({
            otpSessionId: "",
            userData: "",
            isAuthorized: false,
          });
          navigate("/login");
        } else {
          console.log(res?.data);

          setBranches(res?.data);
          localStorage.setItem("branches", JSON.stringify(res?.data));

          if (res?.data?.length < 2) {
            navigate("/dashboard");
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    getgetUserBranchesService();
  }, []);

  if (loading)
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <img
          src="/assets/loading.png"
          alt="loading"
          className="rotate w-16 h-16 cursor-pointer"
        />
      </div>
    );

  return (
    <>
      <div
        className="hidden sm:flex justify-center items-center w-[53%] h-screen rounded-3xl overflow-hidden absolute left-0"
        style={{
          backgroundImage: `url("/assets/login/backImage.svg")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <div className="w-full h-[calc(100vh-89px)] flex flex-col justify-center items-center px-12 sm:px-0 relative z-10">
        <img
          className="relative w-[279px] sm:w-[444px] h-[110px] mb-12"
          src="/assets/svg/ep-logo.svg"
          alt=""
        />

        <h1 className="text-2xl sm:text-4xl text-[#101928] text-center font-bold mb-8">
          الرجاء اختيار ساحة الحجز الذكية للمتابعة
        </h1>

        <div className="flex flex-col sm:flex-row gap-8">
          {branches?.map((branch) => (
            <div
              className="w-[279px] sm:w-[190px] h-[166px] sm:h-[190px] flex flex-col justify-between items-center gap-2 rounded-3xl border-[1px] border-[#d0d5dd] border-solid p-6 bg-white"
              key={branch?.id}
            >
              <div className="flex flex-col justify-center items-center gap-2">
                <p className="text-sm text-[#101928] font-bold opacity-50">
                  ساحة
                </p>
                <p className="text-2xl text-[#101928] font-bold">
                  {branch?.yardName}
                </p>
              </div>
              {/* <Link to={`/dashboard?yard=${branch?.id}`}> */}
              <Link to={`/dashboard`}>
                <button
                  className="flex justify-center items-center w-16 h-9 p-2 bg-[#be1e2d] rounded-3xl"
                  onClick={() => localStorage.setItem("yard", branch?.id)}
                >
                  <img
                    className="relative w-6 h-6"
                    src="/assets/svg/arrow.svg"
                    alt="arrow"
                  />
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
