import { Chart } from "react-google-charts";

export const options = {
  // title: "Company Performance",
  curveType: "function",
  legend: { position: "bottom" },
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 260,
        },
      },
    },
  ],
};

const LineChart = ({ chartData }) => {
  const chartHead = Object.values(chartData?.chartHead);
  const chartDataNumbers = Object.values(chartData?.chartData);
  const data = [chartHead, ...chartDataNumbers?.map((el) => Object.values(el))];

  return (
    <Chart
      chartType="LineChart"
      width="100%"
      height="409px"
      data={data}
      options={options}
    />
  );
};

export default LineChart;
