import { useEffect, useRef, useState } from "react";
import useOutsideAlert from "../../customHooks/useOutsideAlert";
export default function SelectInput({
  options,
  valueKey,
  labelKey,
  value,
  setValue,
  enableSearch = true,
  rounded = true,
}) {
  //#region States
  const [isOpen, setIsOpen] = useState(false);
  const [searchText, setSearchText] = useState(value ?? "+971");
  const [filteredList, setFilteredList] = useState([]);
  const wrapperRef = useRef();

  //#endregion
  //#region use hooks functions
  useOutsideAlert(wrapperRef, setIsOpen);
  useEffect(() => {
    setFilteredList(options);
    setSearchText(value);
  }, [options]);
  //#endregion
  //#region  logic functions
  const filterItems = (text) => {
    if (text == "") setFilteredList(options);
    else {
      let filtered = [];
      if (/^\d+$/.test(text))
        filtered = options?.filter((item) => item?.[valueKey]?.includes(text));
      else
        filtered = options?.filter((item) =>
          item?.[labelKey]?.toLowerCase()?.includes(text.toLowerCase())
        );

      setFilteredList(filtered);
    }
  };
  //#endregion
  //#region events functions
  const onTextChange = (e) => {
    if (enableSearch) {
      setSearchText(e.target?.value);
      filterItems(e.target?.value);
    }
  };
  const handleIconClick = () => {
    if (isOpen && enableSearch) {
      setSearchText("");
      wrapperRef.current.children[0].children[0].focus();
    } else setIsOpen(true);
  };
  const onListItemClick = (item) => {
    setValue(item?.[valueKey]);
    setSearchText(item?.[valueKey]);
    setIsOpen(false);
  };

  //#endregion
  return (
    <>
      <div ref={wrapperRef}>
        <div
          className={`relative w-[123px] h-8 flex items-center gap-2 px-3 py-1 ${
            rounded ? " rounded-sm" : "rounded-s-sm"
          }  bg-white border-r-[1px] border-solid border-[#cacaca]`}
          onClick={() => setIsOpen(true)}
        >
          <img
            width={24}
            height={18}
            src={`https://www.geonames.org/flags/x/${options
              ?.find((el) => el?.dial_code === value)
              .code?.toLowerCase()}.gif`}
            className="w-6 h-4 cursor-pointer"
            //   onClick={handleIconClick}
          />
          <input
            className={`w-full text-[#000]  py-1 text-base font-bold  inline-flex items-center ${
              enableSearch ? "" : "focus:cursor-pointer"
            } `}
            onFocus={() => setIsOpen(true)}
            onChange={onTextChange}
            value={searchText}
            onBlur={() => {
              if (filteredList.length == 0 || searchText == "")
                setSearchText("+971");
            }}
          />

          <img
            //   width={16}
            //   height={16}
            src={`/assets/svg/down.svg`}
            className="cursor-pointer"
            //   onClick={handleIconClick}
          />

          {/* <span className="absolute h-full   end-3 top-0 flex flex-row items-center justify-center">
          <img
            width={16}
            height={16}
            src={`/assets/icons/${
              isOpen && enableSearch
                ? "header/sidemenu/close.svg"
                : "bottomArrow.svg"
            }`}
            className="cursor-pointer"
            onClick={handleIconClick}
          />
        </span> */}
        </div>
        {isOpen ? (
          <ul className="w-[340px] absolute top-[50px] left-0 right-0 z-10 pb-2 bg-white min-h-44 max-h-[202px] overflow-auto rounded-xl border-[1px] border-solid border-[#cacaca] shadow-[0px_4px_16px_#00000026]">
            {filteredList?.map((item) => (
              <li
                className={`block py-2 px-4 hover:bg-gray-100  cursor-pointer  ${
                  value == item?.[valueKey] ? "bg-gray-200" : ""
                }`}
                onClick={() => onListItemClick(item)}
              >
                <span className={`text-black `}>
                  <span>{item?.[labelKey]}</span>{" "}
                  <span dir="ltr">( {item?.[valueKey]} )</span>
                </span>
              </li>
            ))}
          </ul>
        ) : (
          ""
        )}
      </div>
      {/* 
    border-radius: 8px;
    box-shadow: 0px 4px 16px #00000026; */}
    </>
  );
}
