import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-tabs-scrollable";
import "react-tabs-scrollable/dist/rts.css";

const FilterTabs = ({
  tabsFilter,
  setTabsFilter,
  setFilterIds,
  filterData,
  filterIds,
  submitType,
  setSubmitType,
  setPageNumber,
}) => {
  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = useState(1);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  useEffect(() => {
    setTabsFilter(
      filterData.filter((el) => {
        return filterIds?.some((id) => {
          return el?.value === id;
        });
      })
    );
  }, [submitType]);

  return (
    <>
      {tabsFilter?.length > 0 && (
        <Tabs activeTab={activeTab} onTabClick={onTabClick} isRTL>
          {tabsFilter.map((item) => (
            <Tab key={item?.value}>
              <div className="flex justify-center items-center gap-2 h-8 px-2 bg-[#f8f7f1] rounded-md">
                <span className="text-xs text-[#231f20]">{item?.text}</span>
                <img
                  src="/assets/svg/remove.svg"
                  alt="remove"
                  className={`w-4 h-4 cursor-pointer`}
                  onClick={() => {
                    setFilterIds(filterIds.filter((id) => id !== item?.value));
                    setSubmitType(Math?.random());
                    setPageNumber(0);
                  }}
                />
              </div>
            </Tab>
          ))}
        </Tabs>
      )}
    </>
  );
};

export default FilterTabs;
