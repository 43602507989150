import { useContext, useState } from "react";
import { PhrasesContext } from "../../context/phrasesContext";

const CarCard = ({ car }) => {
  const [phrases] = useContext(PhrasesContext);
  const { lblStoreId, lblPlate, lblStoreDate, lblNoPlate } = phrases;

  const [error, setError] = useState(false);

  const {
    brand,
    image,
    storeId,
    plate,
    isReplied,
    isResponded,
    impoundDate,
    requesterName,
    noOfDays,
  } = car;
  return (
    <div className="w-full flex gap-6 border-b-[1px] border-solid border-[#d0d5dd] last:border-0 pb-6">
      {/* border-b-[1px] lg:border-b-0 border-solid border-[#d0d5dd] */}
      <img
        src={error ? "/assets/svg/ep-logo.svg" : image}
        alt=""
        className={`w-[108px] lg:w-[208px] h-[79px] lg:h-[116px] rounded-md ${
          error ? "" : "object-cover"
        }`}
        onError={() => setError(true)}
        style={{
          filter: error ? "grayscale(100%)" : "",
        }}
      />
      <div className="flex flex-col justify-center gap-3">
        {/* <p>
          <span className="text-xs md:text-sm text-[#231f20a3] font-bold">
            جهة الطلب:
          </span>
          <span className="text-sm md:text-base text-[#000000] font-bold">
            {requesterName ? requesterName : "--"}
          </span>
        </p> */}
        <div className="flex  flex-col justify-between flex-wrap md:flex-nowrap gap-2 lg:gap-6">
          <p className="flex items-center gap-[2px]">
            <img
              src="/assets/carDetails/storeidicon.png"
              alt="stored"
              width={32}
            />
            <span className="text-xs md:text-sm text-[#000000de]">
              {`${lblStoreId?.translation}: `}
            </span>
            <span className="text-xs md:text-sm text-[#000000] font-bold">
              {storeId}
            </span>
          </p>
          {/* {window && window?.innerWidth > 777 && <span>•</span>} */}

          <p className="flex items-center gap-[2px]">
            <img
              src="/assets/carDetails/plateicon.png"
              alt="stored"
              width={32}
            />
            <span className="text-xs md:text-sm text-[#000000de]">
              {`${lblPlate?.translation}: `}
            </span>
            <span className="text-xs md:text-sm text-[#000000] font-bold">
              {plate === "--" ? lblNoPlate?.translation : plate}
            </span>
          </p>
          {/* {window && window?.innerWidth > 777 && <span>•</span>} */}
          <p className="flex items-center gap-[2px]">
            <img
              src="/assets/carDetails/dateicon.png"
              alt="stored"
              width={32}
            />
            <span className="text-sm text-[#000000de]">
              {`${lblStoreDate?.translation}: `}
            </span>
            <span className="text-sm text-[#000000] font-bold">
              {new Date(impoundDate).toLocaleDateString()}
            </span>
          </p>
        </div>
        {/* <div className="flex gap-1 md:gap-2">
          <p className="flex items-center gap-1">
            <span className="text-xs md:text-sm text-[#000000de]">
              تمت المخاطبة:
            </span>
            <img src="/assets/svg/yes.svg" alt="" />
            {isResponded ? (
              <span className="text-xs md:text-sm text-[#008755] font-bold">
                نعم{" "}
              </span>
            ) : (
              <span className="text-xs md:text-sm text-[#b91a2a] font-bold">
                لا{" "}
              </span>
            )}
          </p>
          <span>•</span>
          <p className="flex items-center gap-1">
            <span className="text-xs md:text-sm text-[#000000de]">
              تم الرد:{" "}
            </span>
            <img src="/assets/svg/no.svg" alt="" />
            {isReplied ? (
              <span className="text-xs md:text-sm text-[#008755] font-bold">
                نعم{" "}
              </span>
            ) : (
              <span className="text-xs md:text-sm text-[#b91a2a] font-bold">
                لا{" "}
              </span>
            )}
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default CarCard;
