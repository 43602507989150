import { useCallback, useContext, useEffect, useRef, useState } from "react";
import "react-tabs-scrollable/dist/rts.css";
import { PhrasesContext } from "../../context/phrasesContext";
import useItems from "../../customHooks/useItems";
import CarCard from "../CarCard";
import Filter from "../Filter";
import FilterTabs from "../FilterTabs";
import HeaderText from "../HeaderText";

const CarsSideInfinit = ({
  carDetailsId,
  setCarDetailsId,
  carDetailsTitle,
  carDetailsObj,
}) => {
  const [phrases] = useContext(PhrasesContext);
  const { lblVehicleList } = phrases;
  const wrapperRef = useRef();
  const [pageNumber, setPageNumber] = useState(0);
  const [tabsFilter, setTabsFilter] = useState([]);
  const [filterIds, setFilterIds] = useState([]);
  const [submitType, setSubmitType] = useState("");

  const {
    carsData,
    hasMore,
    loading,
    error,
    filterData,
    setFilterData,
    getDetails,
  } = useItems(
    carDetailsId,
    pageNumber,
    20,
    carDetailsObj?.filterColumn,
    filterIds,
    submitType
  );

  const observer = useRef();
  const lastBookElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        document.querySelector("body").style.overflow = "auto";
        document.getElementById("mySidenav").style.width = "0";
        document.getElementById("overlay").style.visibility = "hidden";
        setCarDetailsId(null);
        setTabsFilter([]);
        setFilterIds([]);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    setPageNumber(0);
  }, [carDetailsId]);

  // define state with initial value to let the tabs start with that value
  const [activeTab, setActiveTab] = useState(1);

  // define a onClick function to bind the value on tab click
  const onTabClick = (e, index) => {
    setActiveTab(index);
  };
  return (
    <div
      ref={wrapperRef}
      className="w-0 h-full fixed top-0 right-0 z-[5] overflow-y-scroll pt-[90px] bg-[#FFF] carsSideAnimation"
      id="mySidenav"
    >
      <div className="flex felx-row justify-between items-center gap-4 px-4 lg:px-6 py-4 bg-white sticky top-0 z-10 border-b-[1px] border-solid border-[#0000001a] ">
        <span className="text-xl text-[#231f20] font-bold">
          {carDetailsTitle}
        </span>
        <img
          src="/assets/svg/close.svg"
          alt=""
          className="cursor-pointer"
          onClick={() => {
            document.querySelector("body").style.overflow = "auto";
            document.getElementById("mySidenav").style.width = "0";
            document.getElementById("overlay").style.visibility = "hidden";
            setCarDetailsId(null);
            setTabsFilter([]);
            setFilterIds([]);
          }}
        />
      </div>

      {carsData?.length !== 0 ? (
        <>
          {filterData?.length > 0 && (
            <Filter
              text={carDetailsObj?.filterText}
              filterData={filterData}
              setFilterData={setFilterData}
              getDetails={getDetails}
              filterIds={filterIds}
              setFilterIds={setFilterIds}
              tabsFilter={tabsFilter}
              setTabsFilter={setTabsFilter}
              setSubmitType={setSubmitType}
              setPageNumber={setPageNumber}
            />
          )}
          <FilterTabs
            tabsFilter={tabsFilter}
            setTabsFilter={setTabsFilter}
            filterIds={filterIds}
            setFilterIds={setFilterIds}
            filterData={filterData}
            submitType={submitType}
            setSubmitType={setSubmitType}
            setPageNumber={setPageNumber}
          />

          <div className="flex flex-col gap-6 px-6 py-7">
            <HeaderText
              text={lblVehicleList?.translation}
              icon="/assets/svg/car-front.svg"
            />
            <>
              {carsData?.map((car, index) => {
                if (carsData?.length === index + 1) {
                  return (
                    <div ref={lastBookElementRef} key={car?.brand}>
                      <CarCard key={index} car={car} />
                    </div>
                  );
                } else {
                  return <CarCard key={index} car={car} />;
                }
              })}
              {loading && (
                <div className="flex justify-center items-center h-[80px]">
                  <img
                    src="/assets/loading.png"
                    alt=""
                    className="rotate w-16 h-16 cursor-pointer"
                  />
                </div>
              )}
              {error && "Error"}
            </>
          </div>
        </>
      ) : carsData?.length === 0 && loading && carDetailsId != null ? (
        <div className="flex justify-center items-center h-[80vh]">
          <img
            src="/assets/loading.png"
            alt=""
            className="rotate w-16 h-16 cursor-pointer"
          />
        </div>
      ) : carDetailsId != null ? (
        <div className="flex flex-col justify-center items-center gap-4 h-[80vh]">
          <img src="/assets/search/no-result.svg" alt="" />
          <span>عذرا، لا يوجد نتائج</span>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default CarsSideInfinit;
