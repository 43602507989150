import { useRef, useState } from "react";
import useOutsideAlert from "../../customHooks/useOutsideAlert";

const Filter = ({
  text,
  filterData,
  setFilterData,
  getDetails,
  filterIds,
  setFilterIds,
  tabsFilter,
  setTabsFilter,
  setSubmitType,
  setPageNumber,
}) => {
  const [dropdown, setDropdown] = useState(false);
  const wrapperRef = useRef();

  useOutsideAlert(wrapperRef, setDropdown);

  return (
    <div className=" px-6 pt-6">
      <div
        ref={wrapperRef}
        className={`flex w-full sm:w-auto border-[1px] border-[#d0d5dd] border-solid relative ${
          dropdown ? "rounded-tl-md rounded-tr-md" : "rounded-md"
        }`}
      >
        <div
          className={`w-full h-12 bg-white flex justify-between items-center p-4 rounded-md cursor-pointer`}
          onClick={() => setDropdown(!dropdown)}
        >
          <div className="flex justify-center items-center gap-4">
            <img
              src="/assets/svg/filter.svg"
              alt="arrow"
              className={`w-6 h-6`}
            />
            <span className="text-sm text-[#333333] font-bold">{text}</span>
          </div>
          <img
            src="/assets/svg/arrow-down.svg"
            alt="arrow"
            className={`w-6 h-6 ${dropdown ? "rotate-180" : ""}`}
          />
        </div>
        {dropdown && (
          <div
            className=" absolute top-[50px] z-10 flex flex-col gap-2 w-full p-2 bg-white border-[1px] border-t-0 border-[#d0d5dd] border-solid origin-top-right "
            style={{
              boxShadow:
                "0 14px 32px 0 rgba(39, 44, 52, 0.16), 0 0 8px 0 rgba(39, 44, 52, 0.12)",
            }}
          >
            {filterData?.map((filter, index) => (
              <div
                key={index}
                className={`w-full h-10 bg-white flex items-center gap-4 p-4 cursor-pointer border-[1px] border-solid rounded-md ${
                  filterIds?.includes(filter?.value)
                    ? "border-[#856c49]"
                    : "border-[#d0d5dd]"
                }`}
                // onClick={() => {
                //   setFilterIds(filter?.value);
                //   setDropdown(!dropdown);
                // }}
                onClick={() => {
                  if (!filterIds?.includes(filter?.value)) {
                    setFilterIds([...new Set([...filterIds, filter?.value])]);
                  } else {
                    setFilterIds(
                      filterIds.filter((item) => item !== filter?.value)
                    );
                  }
                }}
              >
                {filterIds?.includes(filter?.value) ? (
                  <img
                    src="/assets/svg/selected.svg"
                    alt="arrow"
                    className={`w-5 h-5`}
                  />
                ) : (
                  <div className={`w-5 h-5`}></div>
                )}
                <span className="text-sm text-[#333333] font-bold">
                  {filter?.text}
                </span>
              </div>
            ))}
            <div className="flex justify-end items-center gap-4 my-2">
              <button
                className=" h-10 bg-[#f8f7f1] rounded-md border-[1px] border-solid border-[#d0d5dd] px-6"
                onClick={() => {
                  // if (filterIds?.length > 0) {
                  setDropdown(false);
                  setFilterIds((prev) => []);
                  setPageNumber(0);
                  setSubmitType("reset");
                  // }
                }}
              >
                <span className="text-sm text-[#231f20] font-bold">
                  إعادة تعيين
                </span>
              </button>
              <button
                className={`h-10 bg-[#bd202e] rounded-md px-6`}
                // ${
                //   filterIds?.length > 0 ? "" : "opacity-80 cursor-not-allowed"
                // }
                onClick={() => {
                  // if (filterIds?.length > 0) {
                  setSubmitType(Math?.random());
                  setDropdown(false);
                  setPageNumber(0);
                  setTabsFilter(
                    filterData.filter((item) => {
                      return filterIds?.some((id) => {
                        return item?.value === id;
                      });
                    })
                  );

                  // }
                }}
              >
                <span className="text-sm text-white font-bold">تطبيق</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Filter;
