export const countriesAr = [
  {
    code: "AD",
    name: "أندورا",
    dial_code: "+376",
  },
  {
    code: "AE",
    name: "الامارات العربية المتحدة",
    dial_code: "+971",
  },
  {
    code: "AF",
    name: "أفغانستان",
    dial_code: "+93",
  },
  {
    code: "AG",
    name: "أنتيجوا وبربودا",
    dial_code: "+1",
  },
  {
    code: "AI",
    name: "أنجويلا",
    dial_code: "+1",
  },
  {
    code: "AL",
    name: "ألبانيا",
    dial_code: "+355",
  },
  {
    code: "AM",
    name: "أرمينيا",
    dial_code: "+374",
  },
  {
    code: "AO",
    name: "أنجولا",
    dial_code: "+244",
  },
  {
    code: "AQ",
    name: "القطب الجنوبي",
    dial_code: "+672",
  },
  {
    code: "AR",
    name: "الأرجنتين",
    dial_code: "+54",
  },
  {
    code: "AT",
    name: "النمسا",
    dial_code: "+43",
  },
  {
    code: "AU",
    name: "أستراليا",
    dial_code: "+61",
  },
  {
    code: "AW",
    name: "آروبا",
    dial_code: "+297",
  },
  {
    code: "AX",
    name: "جزر أولان",
    dial_code: "+358",
  },
  {
    code: "AZ",
    name: "أذربيجان",
    dial_code: "+994",
  },
  {
    code: "BA",
    name: "البوسنة والهرسك",
    dial_code: "+387",
  },
  {
    code: "BB",
    name: "بربادوس",
    dial_code: "+1",
  },
  {
    code: "BD",
    name: "بنجلاديش",
    dial_code: "+880",
  },
  {
    code: "BE",
    name: "بلجيكا",
    dial_code: "+32",
  },
  {
    code: "BF",
    name: "بوركينا فاسو",
    dial_code: "+226",
  },
  {
    code: "BG",
    name: "بلغاريا",
    dial_code: "+359",
  },
  {
    code: "BH",
    name: "البحرين",
    dial_code: "+973",
  },
  {
    code: "BI",
    name: "بوروندي",
    dial_code: "+257",
  },
  {
    code: "BJ",
    name: "بنين",
    dial_code: "+229",
  },
  {
    code: "BL",
    name: "سان بارتيلمي",
    dial_code: "+590",
  },
  {
    code: "BM",
    name: "برمودا",
    dial_code: "+1",
  },
  {
    code: "BN",
    name: "بروناي",
    dial_code: "+673",
  },
  {
    code: "BO",
    name: "بوليفيا",
    dial_code: "+591",
  },
  {
    code: "BQ",
    name: "بونير",
    dial_code: "+599",
  },
  {
    code: "BR",
    name: "البرازيل",
    dial_code: "+55",
  },
  {
    code: "BS",
    name: "الباهاما",
    dial_code: "+1",
  },
  {
    code: "BT",
    name: "بوتان",
    dial_code: "+975",
  },
  {
    code: "BV",
    name: "جزيرة بوفيه",
    dial_code: "+47",
  },
  {
    code: "AS",
    name: "ساموا الأمريكية",
    dial_code: "+1",
  },
  {
    code: "BW",
    name: "بتسوانا",
    dial_code: "+267",
  },
  {
    code: "BY",
    name: "روسيا البيضاء",
    dial_code: "+375",
  },
  {
    code: "BZ",
    name: "بليز",
    dial_code: "+501",
  },
  {
    code: "CA",
    name: "كندا",
    dial_code: "+1",
  },
  {
    code: "CC",
    name: "جزر كوكوس",
    dial_code: "+61",
  },
  {
    code: "CD",
    name: "جمهورية الكونغو الديمقراطية",
    dial_code: "+243",
  },
  {
    code: "CF",
    name: "جمهورية افريقيا الوسطى",
    dial_code: "+236",
  },
  {
    code: "CG",
    name: "الكونغو - برازافيل",
    dial_code: "+242",
  },
  {
    code: "CH",
    name: "سويسرا",
    dial_code: "+41",
  },
  {
    code: "CI",
    name: "ساحل العاج",
    dial_code: "+225",
  },
  {
    code: "CK",
    name: "جزر كوك",
    dial_code: "+682",
  },
  {
    code: "CL",
    name: "شيلي",
    dial_code: "+56",
  },
  {
    code: "CM",
    name: "الكاميرون",
    dial_code: "+237",
  },
  {
    code: "CN",
    name: "الصين",
    dial_code: "+86",
  },
  {
    code: "CO",
    name: "كولومبيا",
    dial_code: "+57",
  },
  {
    code: "CR",
    name: "كوستاريكا",
    dial_code: "+506",
  },
  {
    code: "CU",
    name: "كوبا",
    dial_code: "+53",
  },
  {
    code: "CV",
    name: "الرأس الأخضر",
    dial_code: "+238",
  },
  {
    code: "CW",
    name: "كوراساو",
    dial_code: "+599",
  },
  {
    code: "CX",
    name: "جزيرة الكريسماس",
    dial_code: "+61",
  },
  {
    code: "CY",
    name: "قبرص",
    dial_code: "+357",
  },
  {
    code: "CZ",
    name: "جمهورية التشيك",
    dial_code: "+420",
  },
  {
    code: "DE",
    name: "ألمانيا",
    dial_code: "+49",
  },
  {
    code: "DJ",
    name: "جيبوتي",
    dial_code: "+253",
  },
  {
    code: "DK",
    name: "الدانمرك",
    dial_code: "+45",
  },
  {
    code: "DM",
    name: "دومينيكا",
    dial_code: "+1",
  },
  {
    code: "DO",
    name: "جمهورية الدومينيك",
    dial_code: "+1",
  },
  {
    code: "DZ",
    name: "الجزائر",
    dial_code: "+213",
  },
  {
    code: "EC",
    name: "الاكوادور",
    dial_code: "+593",
  },
  {
    code: "EE",
    name: "استونيا",
    dial_code: "+372",
  },
  {
    code: "EG",
    name: "مصر",
    dial_code: "+20",
  },
  {
    code: "EH",
    name: "الصحراء الغربية",
    dial_code: "+212",
  },
  {
    code: "ER",
    name: "اريتريا",
    dial_code: "+291",
  },
  {
    code: "ES",
    name: "أسبانيا",
    dial_code: "+34",
  },
  {
    code: "ET",
    name: "اثيوبيا",
    dial_code: "+251",
  },
  {
    code: "FI",
    name: "فنلندا",
    dial_code: "+358",
  },
  {
    code: "FJ",
    name: "فيجي",
    dial_code: "+679",
  },
  {
    code: "FK",
    name: "جزر فوكلاند",
    dial_code: "+500",
  },
  {
    code: "FM",
    name: "ميكرونيزيا",
    dial_code: "+691",
  },
  {
    code: "FO",
    name: "جزر فارو",
    dial_code: "+298",
  },
  {
    code: "FR",
    name: "فرنسا",
    dial_code: "+33",
  },
  {
    code: "GA",
    name: "الجابون",
    dial_code: "+241",
  },
  {
    code: "GB",
    name: "المملكة المتحدة",
    dial_code: "+44",
  },
  {
    code: "GD",
    name: "جرينادا",
    dial_code: "+1",
  },
  {
    code: "GE",
    name: "جورجيا",
    dial_code: "+995",
  },
  {
    code: "GF",
    name: "غويانا",
    dial_code: "+594",
  },
  {
    code: "GG",
    name: "غيرنزي",
    dial_code: "+44",
  },
  {
    code: "GH",
    name: "غانا",
    dial_code: "+233",
  },
  {
    code: "GI",
    name: "جبل طارق",
    dial_code: "+350",
  },
  {
    code: "GL",
    name: "جرينلاند",
    dial_code: "+299",
  },
  {
    code: "GM",
    name: "غامبيا",
    dial_code: "+220",
  },
  {
    code: "GN",
    name: "غينيا",
    dial_code: "+224",
  },
  {
    code: "GP",
    name: "جوادلوب",
    dial_code: "+590",
  },
  {
    code: "GQ",
    name: "غينيا الاستوائية",
    dial_code: "+240",
  },
  {
    code: "GR",
    name: "اليونان",
    dial_code: "+30",
  },
  {
    code: "GS",
    name: "جورجيا الجنوبية وجزر ساندويتش الجنوبية",
    dial_code: "+500",
  },
  {
    code: "GT",
    name: "جواتيمالا",
    dial_code: "+502",
  },
  {
    code: "GU",
    name: "جوام",
    dial_code: "+1",
  },
  {
    code: "GW",
    name: "غينيا بيساو",
    dial_code: "+245",
  },
  {
    code: "GY",
    name: "غيانا",
    dial_code: "+595",
  },
  {
    code: "HK",
    name: "هونج كونج الصينية",
    dial_code: "+852",
  },
  {
    code: "HM",
    name: "جزيرة هيرد وماكدونالد",
    dial_code: "",
  },
  {
    code: "HN",
    name: "هندوراس",
    dial_code: "+504",
  },
  {
    code: "HR",
    name: "كرواتيا",
    dial_code: "+385",
  },
  {
    code: "HT",
    name: "هايتي",
    dial_code: "+509",
  },
  {
    code: "HU",
    name: "المجر",
    dial_code: "+36",
  },
  {
    code: "ID",
    name: "اندونيسيا",
    dial_code: "+62",
  },
  {
    code: "IE",
    name: "أيرلندا",
    dial_code: "+353",
  },
  {
    code: "IL",
    name: "اسرائيل",
    dial_code: "+972",
  },
  {
    code: "IM",
    name: "جزيرة مان",
    dial_code: "+44",
  },
  {
    code: "IN",
    name: "الهند",
    dial_code: "+91",
  },
  {
    code: "IO",
    name: "المحيط الهندي البريطاني",
    dial_code: "+246",
  },
  {
    code: "IQ",
    name: "العراق",
    dial_code: "+964",
  },
  {
    code: "IR",
    name: "ايران",
    dial_code: "+98",
  },
  {
    code: "IS",
    name: "أيسلندا",
    dial_code: "+354",
  },
  {
    code: "IT",
    name: "ايطاليا",
    dial_code: "+39",
  },
  {
    code: "JE",
    name: "جيرسي",
    dial_code: "+44",
  },
  {
    code: "JM",
    name: "جامايكا",
    dial_code: "+1",
  },
  {
    code: "JO",
    name: "الأردن",
    dial_code: "+962",
  },
  {
    code: "JP",
    name: "اليابان",
    dial_code: "+81",
  },
  {
    code: "KE",
    name: "كينيا",
    dial_code: "+254",
  },
  {
    code: "KG",
    name: "قرغيزستان",
    dial_code: "+996",
  },
  {
    code: "KH",
    name: "كمبوديا",
    dial_code: "+855",
  },
  {
    code: "KI",
    name: "كيريباتي",
    dial_code: "+686",
  },
  {
    code: "KM",
    name: "جزر القمر",
    dial_code: "+269",
  },
  {
    code: "KN",
    name: "سانت كيتس ونيفيس",
    dial_code: "+1",
  },
  {
    code: "KP",
    name: "كوريا الشمالية",
    dial_code: "+850",
  },
  {
    code: "KR",
    name: "كوريا الجنوبية",
    dial_code: "+82",
  },
  {
    code: "KW",
    name: "الكويت",
    dial_code: "+965",
  },
  {
    code: "KY",
    name: "جزر الكايمن",
    dial_code: "+345",
  },
  {
    code: "KZ",
    name: "كازاخستان",
    dial_code: "+7",
  },
  {
    code: "LA",
    name: "لاوس",
    dial_code: "+856",
  },
  {
    code: "LB",
    name: "لبنان",
    dial_code: "+961",
  },
  {
    code: "LC",
    name: "سانت لوسيا",
    dial_code: "+1",
  },
  {
    code: "LI",
    name: "ليختنشتاين",
    dial_code: "+423",
  },
  {
    code: "LK",
    name: "سريلانكا",
    dial_code: "+94",
  },
  {
    code: "LR",
    name: "ليبيريا",
    dial_code: "+231",
  },
  {
    code: "LS",
    name: "ليسوتو",
    dial_code: "+266",
  },
  {
    code: "LT",
    name: "ليتوانيا",
    dial_code: "+370",
  },
  {
    code: "LU",
    name: "لوكسمبورج",
    dial_code: "+352",
  },
  {
    code: "LV",
    name: "لاتفيا",
    dial_code: "+371",
  },
  {
    code: "LY",
    name: "ليبيا",
    dial_code: "+218",
  },
  {
    code: "MA",
    name: "المغرب",
    dial_code: "+212",
  },
  {
    code: "MC",
    name: "موناكو",
    dial_code: "+377",
  },
  {
    code: "MD",
    name: "مولدافيا",
    dial_code: "+373",
  },
  {
    code: "ME",
    name: "الجبل الأسود",
    dial_code: "+382",
  },
  {
    code: "MF",
    name: "سانت مارتين",
    dial_code: "+590",
  },
  {
    code: "MG",
    name: "مدغشقر",
    dial_code: "+261",
  },
  {
    code: "MH",
    name: "جزر المارشال",
    dial_code: "+692",
  },
  {
    code: "MK",
    name: "مقدونيا",
    dial_code: "+389",
  },
  {
    code: "ML",
    name: "مالي",
    dial_code: "+223",
  },
  {
    code: "MM",
    name: "ميانمار",
    dial_code: "+95",
  },
  {
    code: "MN",
    name: "منغوليا",
    dial_code: "+976",
  },
  {
    code: "MO",
    name: "ماكاو الصينية",
    dial_code: "+853",
  },
  {
    code: "MP",
    name: "جزر ماريانا الشمالية",
    dial_code: "+1",
  },
  {
    code: "MQ",
    name: "مارتينيك",
    dial_code: "+596",
  },
  {
    code: "MR",
    name: "موريتانيا",
    dial_code: "+222",
  },
  {
    code: "MS",
    name: "مونتسرات",
    dial_code: "+1",
  },
  {
    code: "MT",
    name: "مالطا",
    dial_code: "+356",
  },
  {
    code: "MU",
    name: "موريشيوس",
    dial_code: "+230",
  },
  {
    code: "MV",
    name: "جزر الملديف",
    dial_code: "+960",
  },
  {
    code: "MW",
    name: "ملاوي",
    dial_code: "+265",
  },
  {
    code: "MX",
    name: "المكسيك",
    dial_code: "+52",
  },
  {
    code: "MY",
    name: "ماليزيا",
    dial_code: "+60",
  },
  {
    code: "MZ",
    name: "موزمبيق",
    dial_code: "+258",
  },
  {
    code: "NA",
    name: "ناميبيا",
    dial_code: "+264",
  },
  {
    code: "NC",
    name: "كاليدونيا الجديدة",
    dial_code: "+687",
  },
  {
    code: "NE",
    name: "النيجر",
    dial_code: "+227",
  },
  {
    code: "NF",
    name: "جزيرة نورفوك",
    dial_code: "+672",
  },
  {
    code: "NG",
    name: "نيجيريا",
    dial_code: "+234",
  },
  {
    code: "NI",
    name: "نيكاراجوا",
    dial_code: "+505",
  },
  {
    code: "NL",
    name: "هولندا",
    dial_code: "+31",
  },
  {
    code: "NO",
    name: "النرويج",
    dial_code: "+47",
  },
  {
    code: "NP",
    name: "نيبال",
    dial_code: "+977",
  },
  {
    code: "NR",
    name: "نورو",
    dial_code: "+674",
  },
  {
    code: "NU",
    name: "نيوي",
    dial_code: "+683",
  },
  {
    code: "NZ",
    name: "نيوزيلاندا",
    dial_code: "+64",
  },
  {
    code: "OM",
    name: "عمان",
    dial_code: "+968",
  },
  {
    code: "PA",
    name: "بنما",
    dial_code: "+507",
  },
  {
    code: "PE",
    name: "بيرو",
    dial_code: "+51",
  },
  {
    code: "PF",
    name: "بولينيزيا الفرنسية",
    dial_code: "+689",
  },
  {
    code: "PG",
    name: "بابوا غينيا الجديدة",
    dial_code: "+675",
  },
  {
    code: "PH",
    name: "الفيلبين",
    dial_code: "+63",
  },
  {
    code: "PK",
    name: "باكستان",
    dial_code: "+92",
  },
  {
    code: "PL",
    name: "بولندا",
    dial_code: "+48",
  },
  {
    code: "PM",
    name: "سانت بيير وميكولون",
    dial_code: "+508",
  },
  {
    code: "PN",
    name: "بتكايرن",
    dial_code: "+872",
  },
  {
    code: "PR",
    name: "بورتوريكو",
    dial_code: "+1",
  },
  {
    code: "PS",
    name: "فلسطين",
    dial_code: "+970",
  },
  {
    code: "PT",
    name: "البرتغال",
    dial_code: "+351",
  },
  {
    code: "PW",
    name: "بالاو",
    dial_code: "+680",
  },
  {
    code: "PY",
    name: "باراجواي",
    dial_code: "+595",
  },
  {
    code: "QA",
    name: "قطر",
    dial_code: "+974",
  },
  {
    code: "RE",
    name: "روينيون",
    dial_code: "+262",
  },
  {
    code: "RO",
    name: "رومانيا",
    dial_code: "+40",
  },
  {
    code: "RS",
    name: "صربيا",
    dial_code: "+381",
  },
  {
    code: "RU",
    name: "روسيا",
    dial_code: "+7",
  },
  {
    code: "RW",
    name: "رواندا",
    dial_code: "+250",
  },
  {
    code: "SA",
    name: "المملكة العربية السعودية",
    dial_code: "+966",
  },
  {
    code: "SB",
    name: "جزر سليمان",
    dial_code: "+677",
  },
  {
    code: "SC",
    name: "سيشل",
    dial_code: "+248",
  },
  {
    code: "SD",
    name: "السودان",
    dial_code: "+249",
  },
  {
    code: "SE",
    name: "السويد",
    dial_code: "+46",
  },
  {
    code: "SG",
    name: "سنغافورة",
    dial_code: "+65",
  },
  {
    code: "SH",
    name: "سانت هيلنا",
    dial_code: "+290",
  },
  {
    code: "SI",
    name: "سلوفينيا",
    dial_code: "+386",
  },
  {
    code: "SJ",
    name: "سفالبارد وجان مايان",
    dial_code: "+47",
  },
  {
    code: "SK",
    name: "سلوفاكيا",
    dial_code: "+421",
  },
  {
    code: "SL",
    name: "سيراليون",
    dial_code: "+232",
  },
  {
    code: "SM",
    name: "سان مارينو",
    dial_code: "+378",
  },
  {
    code: "SN",
    name: "السنغال",
    dial_code: "+221",
  },
  {
    code: "SO",
    name: "الصومال",
    dial_code: "+252",
  },
  {
    code: "SR",
    name: "سورينام",
    dial_code: "+597",
  },
  {
    code: "SS",
    name: "جنوب السودان",
    dial_code: "+211",
  },
  {
    code: "ST",
    name: "ساو تومي وبرينسيبي",
    dial_code: "+239",
  },
  {
    code: "SV",
    name: "السلفادور",
    dial_code: "+503",
  },
  {
    code: "SX",
    name: "سينت مارتن",
    dial_code: "+1",
  },
  {
    code: "SY",
    name: "سوريا",
    dial_code: "+963",
  },
  {
    code: "SZ",
    name: "سوازيلاند",
    dial_code: "+268",
  },
  {
    code: "TC",
    name: "جزر الترك وجايكوس",
    dial_code: "+1",
  },
  {
    code: "TD",
    name: "تشاد",
    dial_code: "+235",
  },
  {
    code: "TF",
    name: "المقاطعات الجنوبية الفرنسية",
    dial_code: "+262",
  },
  {
    code: "TG",
    name: "توجو",
    dial_code: "+228",
  },
  {
    code: "TH",
    name: "تايلند",
    dial_code: "+66",
  },
  {
    code: "TJ",
    name: "طاجكستان",
    dial_code: "+992",
  },
  {
    code: "TK",
    name: "توكيلو",
    dial_code: "+690",
  },
  {
    code: "TL",
    name: "تيمور الشرقية",
    dial_code: "+670",
  },
  {
    code: "TM",
    name: "تركمانستان",
    dial_code: "+993",
  },
  {
    code: "TN",
    name: "تونس",
    dial_code: "+216",
  },
  {
    code: "TO",
    name: "تونجا",
    dial_code: "+676",
  },
  {
    code: "TR",
    name: "تركيا",
    dial_code: "+90",
  },
  {
    code: "TT",
    name: "ترينيداد وتوباغو",
    dial_code: "+1",
  },
  {
    code: "TV",
    name: "توفالو",
    dial_code: "+688",
  },
  {
    code: "TW",
    name: "تايوان",
    dial_code: "+886",
  },
  {
    code: "TZ",
    name: "تانزانيا",
    dial_code: "+255",
  },
  {
    code: "UA",
    name: "أوكرانيا",
    dial_code: "+380",
  },
  {
    code: "UG",
    name: "أوغندا",
    dial_code: "+256",
  },
  {
    code: "UM",
    name: "جزر الولايات المتحدة البعيدة الصغيرة",
    dial_code: "+246",
  },
  {
    code: "US",
    name: "الولايات المتحدة الأمريكية",
    dial_code: "+1",
  },
  {
    code: "UY",
    name: "أورجواي",
    dial_code: "+598",
  },
  {
    code: "UZ",
    name: "أوزبكستان",
    dial_code: "+998",
  },
  {
    code: "VA",
    name: "الفاتيكان",
    dial_code: "+379",
  },
  {
    code: "VC",
    name: "سانت فنسنت وغرنادين",
    dial_code: "+1",
  },
  {
    code: "VE",
    name: "فنزويلا",
    dial_code: "+58",
  },
  {
    code: "VG",
    name: "جزر فرجين البريطانية",
    dial_code: "+1",
  },
  {
    code: "VI",
    name: "جزر فرجين الأمريكية",
    dial_code: "+1",
  },
  {
    code: "VN",
    name: "فيتنام",
    dial_code: "+84",
  },
  {
    code: "VU",
    name: "فانواتو",
    dial_code: "+678",
  },
  {
    code: "WF",
    name: "جزر والس وفوتونا",
    dial_code: "+681",
  },
  {
    code: "WS",
    name: "ساموا",
    dial_code: "+685",
  },
  {
    code: "XK",
    name: "كوسوفو",
    dial_code: "+383",
  },
  {
    code: "YE",
    name: "اليمن",
    dial_code: "+967",
  },
  {
    code: "YT",
    name: "مايوت",
    dial_code: "+262",
  },
  {
    code: "ZA",
    name: "جمهورية جنوب افريقيا",
    dial_code: "+27",
  },
  {
    code: "ZM",
    name: "زامبيا",
    dial_code: "+260",
  },
  {
    code: "ZW",
    name: "زيمبابوي",
    dial_code: "+263",
  },
];
