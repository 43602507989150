const CardContent = ({ subSection, subSectionsCount }) => {
  return (
    <>
      <p
        // ${
        //   subSectionsCount > 2 ? "max-w-[123px]" : "max-w-full"
        // }
        className={`max-w-full h-8 text-xs text-center text-[#000000] font-bold mb-2`}
      >
        {subSection?.title}
        {/* مطلوب لمراكز شرطة ونيابة اماره راس الخيمة */}
      </p>
      <p
        className={`w-full flex gap-3 text-[40px] font-bold leading-[1.1] mb-1 ${
          subSection?.openSideMenu ? "justify-between" : "justify-center"
        }`}
        style={{ color: `${subSection?.countColor}` }}
      >
        {subSection?.openSideMenu && <span className="w-6"></span>}
        <span>
          {Math.sign(subSection?.count) === -1 ? "-" : subSection?.count}
        </span>
        {subSection?.openSideMenu && Math.sign(subSection?.count) === 1 ? (
          <img src="/assets/svg/angle-left.svg" alt="" />
        ) : !subSection?.openSideMenu ? null : (
          <span className="w-6"></span>
        )}
      </p>
      <p className={`text-sm text-[#231f20a3] font-bold`}>
        {subSection?.countTitle}
      </p>
    </>
  );
};

export default CardContent;
