import { Fragment } from "react";

const CardContentOneItem = ({
  subSections,
  onClick,
  setCarDetailsTitle,
  setCarDetailsId,
  setCarDetailsObj,
}) => {
  return (
    <div className="pt-4">
      <p className="text-sm text-[#000000] text-center font-bold">
        {subSections[0]?.title}
      </p>
      <div className="flex justify-around items-center bg-white">
        {subSections?.map((subSection, index) => (
          <Fragment key={index}>
            <div
              className={`flex flex-col justify-center items-center p-4  ${
                subSection?.openSideMenu && Math.sign(subSection?.count) === 1
                  ? "cursor-pointer"
                  : ""
              }`}
              onClick={() => {
                if (
                  subSection?.openSideMenu &&
                  Math.sign(subSection?.count) === 1
                ) {
                  onClick();
                  setCarDetailsTitle(subSection?.sideMenuLabel);
                  setCarDetailsId(subSection?.id);
                  setCarDetailsObj(subSection);
                  // setCarDetailsId(subSection?.code ? subSection?.code : 1);
                }
              }}
            >
              <p className="text-[40px] text-[#231f20] font-bold">
                {Math.sign(subSection?.count) === -1 ? "-" : subSection?.count}
              </p>
              <p className="text-sm text-[#231f20a3] font-bold">
                {subSection?.countTitle}
              </p>
            </div>
            {index === 0 && <img src="/assets/svg/angle-left.svg" alt="" />}
          </Fragment>
        ))}
        {/* <div className="flex flex-col justify-center items-center p-4">
          <p className="text-[40px] text-[#231f20] font-bold">114</p>
          <p className="text-sm text-[#231f20a3] font-bold">مركبة</p>
        </div>
        <img src="/assets/svg/angle-left.svg" alt="" />
        <div className="flex flex-col justify-center items-center p-4">
          <p className="text-[40px] text-[#231f20] font-bold">114</p>
          <p className="text-sm text-[#231f20a3] font-bold">مركبة</p>
        </div> */}
      </div>
    </div>
  );
};

export default CardContentOneItem;
