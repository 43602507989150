"use client";
import { useEffect, useState } from "react";
import Verification from "../../modules/verification";

export default function Otp() {
  const [openCarsSide, setOpenCarsSide] = useState(false);

  useEffect(() => {
    // getDashitemsService();
  }, []);
  return <Verification />;
}
