import CardContentOneItem from "./CardContentOneItem";
import CardContentOneRow from "./CardContentOneRow";
import CardContentTwoRow from "./CardContentTwoRow";
import CardHeader from "./CardHeader";
import LineChart from "./LineChart";
import Logo from "./Logo";
import PieChart from "./PieChart";

const CardLayout = ({
  title,
  subSections,
  chartData,
  totalPercentage,
  color,
  background,
  cardType,
  icon,
  openCarsSide,
  onClick,
  setCarDetailsTitle,
  setCarDetailsId,
  setCarDetailsObj,
}) => {
  return (
    <div
      className={`relative w-full flex flex-col rounded-xl bg-white border-[1px] border-solid border-[#d0d5dd] shadow-[0_1px_4px_0_#231f201f] overflow-hidden ${
        cardType === "logo" ? " flex-1" : ""
      } ${cardType === "lineChart" ? " h-full" : "h-fit"}`}

      // ${
      //   cardType === "pieChart"
      //     ? "w-full lg:w-[33%]"
      //     : cardType === "lineChart"
      //     ? "w-full lg:w-[67%]"
      //     : ""
      // }
    >
      {cardType !== "logo" && (
        <CardHeader
          text={title}
          color={color}
          background={background}
          icon={icon}
        />
      )}
      {cardType === "normal" ? (
        <CardContentOneItem
          subSections={subSections}
          onClick={onClick}
          setCarDetailsTitle={setCarDetailsTitle}
          setCarDetailsId={setCarDetailsId}
          setCarDetailsObj={setCarDetailsObj}
        />
      ) : cardType === "onerow" ? (
        <CardContentOneRow
          subSections={subSections}
          onClick={onClick}
          setCarDetailsTitle={setCarDetailsTitle}
          setCarDetailsId={setCarDetailsId}
          setCarDetailsObj={setCarDetailsObj}
        />
      ) : cardType === "tworow" ? (
        <CardContentTwoRow
          subSections={subSections}
          onClick={onClick}
          setCarDetailsTitle={setCarDetailsTitle}
          setCarDetailsId={setCarDetailsId}
          setCarDetailsObj={setCarDetailsObj}
        />
      ) : cardType === "pieChart" ? (
        <PieChart chartData={chartData} />
      ) : cardType === "lineChart" ? (
        <LineChart chartData={chartData} />
      ) : cardType === "logo" ? (
        <Logo icon={icon} />
      ) : null}
      {/* {totalPercentage && (
        <div className="flex justify-center items-center gap-1 absolute bottom-2 left-2">
          <img src="/assets/svg/arrow-circle-up.svg" alt="" />
          <span className="text-xs text-[#036b26] font-bold leading-[1.2]">
            {`% ${totalPercentage}`}
          </span>
        </div>
      )} */}
    </div>
  );
};

export default CardLayout;
