import { createContext, useContext, useEffect, useState } from "react";

const AuthContext = createContext();

export function AuthWrapper({ children }) {
  const [authStatus, setAuthStatus] = useState({
    // token: localStorage?.getItem("sessionId"),
    // fullName: localStorage?.getItem("fullName"),
    // departmentName: localStorage?.getItem("departmentName"),
    //
    otpSessionId: localStorage?.getItem("otpSessionId"),
    userData: JSON.parse(localStorage?.getItem("userData")),
    isAuthorized: localStorage?.getItem("userData") ? true : false,
  });

  useEffect(() => {}, [authStatus]);

  return (
    <AuthContext.Provider value={{ authStatus, setAuthStatus }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}
