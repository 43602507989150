import { useContext, useEffect, useRef, useState } from "react";
import AuthCode from "react-auth-code-input";
import { useNavigate } from "react-router-dom";
import Button from "../../components/Button";
import LoginLayout from "../../components/loginLayout";
import { useAuthContext } from "../../context/authContext";
import { PhrasesContext } from "../../context/phrasesContext";
import { resendOtp, verifyOtp } from "../../services/api";

export default function Verification() {
  const [phrases] = useContext(PhrasesContext);
  const {
    lblVerificationCode,
    lbl6DigitLabel,
    lblResendCodeIn,
    lblResendLabel,
    btnSMS,
    btnWhatsApp,
  } = phrases;

  const navigate = useNavigate();
  const { setAuthStatus } = useAuthContext();
  const loginNumber = localStorage.getItem("loginNumber");
  const loginCode = localStorage.getItem("loginCode");
  const [openCarsSide, setOpenCarsSide] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [signInTypeNumber, setSignInTypeNumber] = useState(0);
  const [otpValue, setOtpValue] = useState("");
  const [countDown, setCountDown] = useState(30);
  const AuthInputRef = useRef(null);

  useEffect(() => {
    if (localStorage.getItem("userData")) {
      navigate("/");
    }
    if (!localStorage.getItem("otpSessionId")) {
      navigate("/login");
    }
  }, []);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown((countDown) => countDown - 1);
    }, 1000);
    if (countDown <= 0) clearInterval(interval);
    return () => clearInterval(interval);
  }, [countDown]);

  const handleOnChange = (value) => {
    setOtpValue(value);
    if (value?.length < 6) setError("");
    if (value?.length === 6)
      return verifyOtp({
        otp: Number(value),
      })
        .then((res) => {
          if (res == "401") {
            navigate("/login");
          } else {
          if (res?.code === 0) {
            setError(res?.message);
          } else if (res?.code === 1) {
            try {
              localStorage.setItem("userData", JSON.stringify(res?.data));
              setAuthStatus({
                otpSessionId: "",
                userData: res?.data,
                isAuthorized: true,
              });
              navigate("/");
            } catch (error) {}
          } else {
            // navigate("/login");
          }
        }
        })
        .catch((err) => {
          console.log(err);
        });
  };

  // "otp": 0

  const resendOtpService = (signInType) => {
    AuthInputRef?.current?.clear()
    setLoading(true);
    setSignInTypeNumber(signInType);
    return resendOtp({
      signInType: signInType,
    }).then((res) => {
      if (res == "401") {
        navigate("/login");
      } else {
      setLoading(false);
      setSignInTypeNumber(0);
      setCountDown(30);
      if (res?.code === 0) {
      } else {
      }
    }
    });
  };

  return (
    <LoginLayout>
      <h1 className="text-3xl md:text-4xl text-[#be1e2d] text-center font-bold mb-2">
        {lblVerificationCode?.translation}
      </h1>
      <p className="max-w-[338px] text-sm md:text-base text-[#212121] text-center font-medium">
        {lbl6DigitLabel?.translation}
      </p>
      <p className="text-base text-[#212121] font-bold" dir="ltr">
        ({loginCode}) {loginNumber}
      </p>
      <div className="w-full my-8">
        <div className={`container ${error ? "error" : ""} `}>
          <AuthCode
            ref={AuthInputRef} 
            allowedCharacters="numeric"
            length={6}
            onChange={handleOnChange}
          />
        </div>
        <p className="h-4 text-xs text-[#be202e] text-center font-bold mt-2">
          {error && "Invalid OTP Code"}
        </p>
      </div>
      <div className="w-full flex justify-between items-center gap-4 mb-4">
        <span className="w-14 md:w-16 h-[1px] bg-[#cacaca]"></span>
        {countDown <= 0 ? (
          <span className="text-sm text-[#212121] font-medium">
            {lblResendLabel?.translation}
          </span>
        ) : (
          <span className="text-xs text-[#212121] font-medium">
            {lblResendCodeIn?.translation}{" "}
            <span className="text-[#be1e2d] font-bold">
              (00:{countDown <= 0 ? "00" : countDown})
            </span>
          </span>
        )}
        <span className="w-14 md:w-16 h-[1px] bg-[#cacaca]"></span>
      </div>
      {/* <div className="w-[340px] h-[56] flex items-center gap-4 px-4 py-2 border-[1px] border-solid border-[#ff422f] bg-[#ff422f1a] rounded-lg">
        <img
          className="w-6 h-6"
          src="/assets/login/whatsapp.svg"
          alt=""
          width={24}
          height={24}
        />
        <span className="text-base text-[#ff422f] font-medium">
          You’ve reached the maximum resend limit for the OTP code.
        </span>
      </div> */}
      <div className="w-full flex justify-center items-center gap-4">
        {/* <Button
          text={btnWhatsApp?.translation}
          icon="/assets/login/whatsapp.svg"
          disabledIcon="/assets/login/whatsappdim.svg"
          disabled={countDown > 0}
          background="bg-[#00a884]"
          size="large"
          hasBorder
          loading={loading && signInTypeNumber === 2}
          onClick={() => resendOtpService(2)}
        /> */}
        <Button
          text={btnSMS?.translation}
          icon="/assets/login/sms.svg"
          disabledIcon="/assets/login/smsdim.svg"
          background="bg-[#bd202e]"
          disabled={countDown > 0}
          size="large"
          hasBorder
          fullWidth
          loading={loading && signInTypeNumber === 1}
          onClick={() => resendOtpService(1)}
        />
      </div>
      {/* <img
        className="w-28 h-28 mb-6"
        src="/assets/login/stop.svg"
        alt=""
        width={120}
        height={120}
      />
      <h1 className="text-4xl text-[#be1e2d] font-bold mb-2">
        Access Restricted
      </h1>
      <p className="text-base text-[#212121] text-center font-medium">
        It appears you don’t have access to this page, please contact the system
        administrator.
      </p>
      <h1 className="text-4xl text-[#be1e2d] font-bold mb-4">Blocked</h1>
      <p className="text-base text-[#212121] text-center font-medium">
        It appears you have entered wrong OTP many times.
      </p> */}
    </LoginLayout>
  );
}
