import React, { useEffect, useState } from "react";
import { getPhrases } from "../services/api";
import { normalize } from "../utils/helper";

const PhrasesContext = React.createContext([{}, () => {}]);

const PhrasesProvider = ({ children }) => {
  const [phrases, setPhrases] = useState([]);

  useEffect(() => {
    getPhrasesService();
  }, []);

  const getPhrasesService = () => {
    return getPhrases().then((res) => {
      setPhrases(normalize(res?.data, "key"));
    });
  };

  return (
    <PhrasesContext.Provider value={[phrases]}>
      {children}
    </PhrasesContext.Provider>
  );
};

export { PhrasesContext, PhrasesProvider };
