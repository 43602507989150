import { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import GridCard from "../../components/GridCard";
import SearchBar from "../../components/SearchBar";
import SearchBarMobile from "../../components/SearchBarMobile";
import { PhrasesContext } from "../../context/phrasesContext";
import { getSearch } from "../../services/api";

export default function Search() {
  const lang = window.localStorage.getItem("lang");

  const navigate = useNavigate();

  const [phrases] = useContext(PhrasesContext);
  const { lblResultsHeading, lblResultHeadingPlate, lblNoResults } = phrases;
  const [searchParams, setSearchParams] = useSearchParams();
  const q = searchParams.get("q");
  const type = searchParams.get("type");
  const [searchKey, setSearchKey] = useState(q);
  const [searchType, setSearchType] = useState(type ? Number(type) : 1);
  const [searchData, setSearchData] = useState([]);
  const [callSearch, setCallSearch] = useState(false);
  const [loading, setLoading] = useState(false);
  const yardId = 2;

  useEffect(() => {
    if (q) getSearchService(q, type);
  }, []);

  const getSearchService = (value) => {
    setLoading(true);
    setCallSearch(true);
    return getSearch(value, searchType, yardId).then((res) => {
      setLoading(false);
      if (res == "401") {
        navigate("/login");
      } else {
        setSearchData(res?.data);
      }
    });
  };

  return (
    <div>
      <SearchBar
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        getSearchService={getSearchService}
        searchType={searchType}
        setSearchType={setSearchType}
        setSearchData={setSearchData}
        setCallSearch={setCallSearch}
      />
      <SearchBarMobile
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        getSearchService={getSearchService}
        searchType={searchType}
        setSearchType={setSearchType}
        setSearchData={setSearchData}
        setCallSearch={setCallSearch}
      />

      {loading ? (
        <div className="flex justify-center items-center h-[80vh]">
          <img
            src="/assets/loading.png"
            alt=""
            className="rotate w-16 h-16 cursor-pointer"
          />
        </div>
      ) : searchData?.length > 0 ? (
        <div className="px-4 lg:px-0">
          <div className="mt-[66px] md:mt-[132px] mb-4 md:mb-8">
            <span className="text-[#231f20] text-sm md:text-2xl font-100 me-4">
              {searchType === 1
                ? lblResultsHeading?.translation
                : lblResultHeadingPlate?.translation}
            </span>
            <span className="text-[#231f20] text-sm md:text-2xl font-bold">
              {searchKey}
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {searchData?.map((data, index) => (
              <GridCard key={index} data={data} />
            ))}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center gap-10 h-[80vh]">
          <img
            className="w-[182px] h-[140px]"
            src="/assets/search/no-result.svg"
            alt=""
          />
          {callSearch && (
            <p className="text-md text-[#000000] font-bold">
              {lblNoResults?.translation}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
