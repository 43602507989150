const Logo = ({ icon }) => {
  return (
    <div className="flex flex-1 justify-center items-center w-full p-4 lg:py-0 bg-[#FFF] rounded-xl">
      <img
        src={icon}
        alt=" "
        className="w-[150px] lg:w-auto"
        //w-[280px] lg:w-[370px] lg:h-[120px]
      />
    </div>
  );
};

export default Logo;
