const HeaderText = ({ text, icon, size }) => {
  return (
    <div className="flex felx-row items-center gap-4">
      <img src={icon} alt="" />
      <span className="text-base text-[#231f20] font-bold">{text}</span>
    </div>
  );
};

export default HeaderText;
