const BASE_URL = process.env.REACT_APP_BASE_URL;

export async function loginWithOtp(data) {
  try {
    const response = await fetch(`${BASE_URL}Account/login_with_otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Lang: "ar",
        SessionId: null,
      },
      body: JSON.stringify(data),
    });
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function verifyOtp(data) {
  try {
    const response = await fetch(`${BASE_URL}Account/verify_otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        SessionId: localStorage.getItem("otpSessionId"),
        Lang: "ar",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function resendOtp(data) {
  try {
    const response = await fetch(`${BASE_URL}Account/resend_otp`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        SessionId: localStorage.getItem("otpSessionId"),
        Lang: "ar",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getPhrases(lang) {
  try {
    const response = await fetch(`${BASE_URL}Configurations/Phrases`, {
      method: "GET",
      headers: {
        Lang: "ar",
        SessionId: null,
      },
    });
    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getDashitems(YardId) {
  try {
    const response = await fetch(`${BASE_URL}Statistics/Sections`, {
      method: "GET",
      headers: {
        // Authorization: `Bearer ${access_token}`,
        YardId: YardId,
        SessionId: JSON.parse(localStorage.getItem("userData"))?.sessionId,
        Lang: "ar",
      },
    });

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return error;
  }
}

export async function getCardetails(code, pageNumber, pageSize) {
  try {
    const response = await fetch(
      `${BASE_URL}Statistics/SectionDetails?code=${code}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
      {
        method: "GET",
        headers: {
          // Authorization: `Bearer ${access_token}`,
          SessionId: JSON.parse(localStorage.getItem("userData"))?.sessionId,
          Lang: "ar",
        },
      }
    );

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getSearch(searchKey, searchType, yardId) {
  try {
    const response = await fetch(
      `${BASE_URL}Statistics/SearchCars?searchKey=${searchKey}&searchType=${searchType}`,
      {
        method: "GET",
        headers: {
          YardId: yardId,
          SessionId: JSON.parse(localStorage.getItem("userData"))?.sessionId,
          Lang: "ar",
        },
      }
    );

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return [];
  }
}

export async function getUserBranches() {
  try {
    const response = await fetch(`${BASE_URL}Account/UserBranches`, {
      method: "POST",
      headers: {
        SessionId: JSON.parse(localStorage.getItem("userData"))?.sessionId,
        Lang: "ar",
      },
    });

    if (!response.ok) {
      return response.status;
    }

    return await response.json();
  } catch (error) {
    return [];
  }
}
