"use client";
import { useContext, useState } from "react";
import { Link, useHref } from "react-router-dom";
import { useAuthContext } from "../../../context/authContext";
import { PhrasesContext } from "../../../context/phrasesContext";
const User = (props) => {
  // const navigate = useNavigate();
  const [phrases] = useContext(PhrasesContext);
  const { lblLogOut } = phrases;
  const path = useHref();

  const { authStatus, setAuthStatus } = useAuthContext();
  const { userData } = authStatus;
  const { fullName, departmentName } = userData;
  const [openUserMenu, setOpenUserMenu] = useState(false);

  return (
    <div className="relative flex justify-center items-center gap-2">
      <div className="hidden lg:flex flex-row gap-4 items-center rounded-4 px-2">
        <div className="flex flex-row items-center gap-3">
          <div className="flex items-center justify-center w-12 h-12 bg-[#f8f7f1] rounded-full">
            <img
              src="/assets/svg/user_icon.svg"
              alt="logout"
              className="w-6 h-6"
            />
          </div>
          <div className="flex flex-col gap-1">
            <span className="leading-none text-[14px] font-bold text-[#010202]">
              {fullName}
            </span>
            <span className="leading-none text-[12px] text-[#010202] opacity-50">
              {departmentName}
            </span>
          </div>
        </div>
        <div
          className="flex items-center justify-center w-9 h-9 rounded-full bg-[#FFFFFF] cursor-pointer border-[1px] border-solid border-[#d0d5dd]"
          onClick={() => setOpenUserMenu(!openUserMenu)}
        >
          {openUserMenu ? (
            <img
              src="/assets/svg/arrow-down.svg"
              alt="logout"
              className="w-5 h-5 rotate-180"
            />
          ) : (
            <img
              src="/assets/svg/arrow-down.svg"
              alt="logout"
              className="w-5 h-5"
            />
          )}
        </div>
      </div>
      {path !== "/" && (
        <Link to="/search">
          <img
            src="/assets/search/search.svg"
            alt="search"
            className=" md:hidden w-5 h-5"
          />
        </Link>
      )}
      <div className=" w-fit flex flex-row lg:hidden  gap-1 items-center rounded-4 p-3 rounded-3xl bg-[#f8f7f1]">
        <img src="/assets/svg/user_icon.svg" alt="logout" className="w-6 h-6" />
        <div
          onClick={() => setOpenUserMenu(!openUserMenu)}
          className="cursor-pointer"
        >
          {openUserMenu ? (
            <img
              src="/assets/svg/arrow-down.svg"
              alt="logout"
              className="w-5 h-5 rotate-180"
            />
          ) : (
            <img
              src="/assets/svg/arrow-down.svg"
              alt="logout"
              className="w-5 h-5"
            />
          )}
        </div>
      </div>
      {/* {openUserMenu && ( */}
      {/*  */}
      {/* box-shadow: 0px 8px 28px rgba(0,0,0,0.08);
    border-radius: 8px;
    padding: 4px; */}
      {openUserMenu && (
        <div className="w-max flex flex-col bg-[#FFFFFF] border-1 border-[#EEE2D0] rounded-lg absolute top-[68px] left-0 shadow-[0px_8px_28px_rgba(0,0,0,0.08)] p-1">
          <div
            className="flex flex-row gap-3 items-center h-10 px-4 hover:bg-[#F9F7ED] cursor-pointer rounded-lg"
            onClick={() => {
              // localStorage.removeItem("access_token");
              // localStorage.removeItem("fullName");
              localStorage.removeItem("otpSessionId");
              localStorage.removeItem("loginCode");
              localStorage.removeItem("loginNumber");
              localStorage.removeItem("userData");
              setOpenUserMenu(!openUserMenu);
              setAuthStatus({
                otpSessionId: "",
                userData: "",
                isAuthorized: false,
              });
              // navigate("/login");
              window.location.href = "/login";
            }}
          >
            <img
              src="/assets/login/logout.svg"
              alt="logout"
              className="w-5 h-5 rotate-180"
            />
            <span className="text-sm text-[#010202] hover:text-[#5D3B26]">
              {lblLogOut?.translation}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default User;
